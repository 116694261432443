<template>
  <div class="homePage">
    <div style="height: 5px;background-color: #f1f2f6;"></div>
    <!--    8个块,身份-->
    <div class="status">
      <div class="status-top">
        <div class="status-top-content">
          <div class="status-top-content-font">医生</div>
          <div class="status-top-content-number">
            <div class="status-top-content-number-left">{{num.t1}}</div>
            <div class="status-top-content-number-right">{{todayStu.t1}}</div>
          </div>
          <div class="status-top-content-font-bottom">
            <div class="status-top-content-font-left">总</div>
            <div class="status-top-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-top-content">
          <div class="status-top-content-font">护士</div>
          <div class="status-top-content-number">
            <div class="status-top-content-number-left">{{num.t2}}</div>
            <div class="status-top-content-number-right">{{todayStu.t2}}</div>
          </div>
          <div class="status-top-content-font-bottom">
            <div class="status-top-content-font-left">总</div>
            <div class="status-top-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-top-content">
          <div class="status-top-content-font">医技</div>
          <div class="status-top-content-number">
            <div class="status-top-content-number-left">{{num.t3}}</div>
            <div class="status-top-content-number-right">{{todayStu.t3}}</div>
          </div>
          <div class="status-top-content-font-bottom">
            <div class="status-top-content-font-left">总</div>
            <div class="status-top-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-top-content">
          <div class="status-top-content-font">药师</div>
          <div class="status-top-content-number">
            <div class="status-top-content-number-left">{{num.t4}}</div>
            <div class="status-top-content-number-right">{{todayStu.t4}}</div>
          </div>
          <div class="status-top-content-font-bottom">
            <div class="status-top-content-font-left">总</div>
            <div class="status-top-content-font-right">今日</div>
          </div>
        </div>
      </div>

      <div class="status-bottom">
        <div class="status-bottom-content">
          <div class="status-bottom-content-font">学生</div>
          <div class="status-bottom-content-number">
            <div class="status-bottom-content-number-left">{{num.t5}}</div>
            <div class="status-bottom-content-number-right">{{todayStu.t5}}</div>
          </div>
          <div class="status-bottom-content-font-bottom">
            <div class="status-bottom-content-font-left">总</div>
            <div class="status-bottom-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-bottom-content">
          <div class="status-bottom-content-font">其他医务从业者</div>
          <div class="status-bottom-content-number">
            <div class="status-bottom-content-number-left">{{num.t6}}</div>
            <div class="status-bottom-content-number-right">{{todayStu.t6}}</div>
          </div>
          <div class="status-bottom-content-font-bottom">
            <div class="status-bottom-content-font-left">总</div>
            <div class="status-bottom-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-bottom-content">
          <div class="status-bottom-content-font">非医务从业者</div>
          <div class="status-bottom-content-number">
            <div class="status-bottom-content-number-left">{{num.t7}}</div>
            <div class="status-bottom-content-number-right">{{todayStu.t7}}</div>
          </div>
          <div class="status-bottom-content-font-bottom">
            <div class="status-bottom-content-font-left">总</div>
            <div class="status-bottom-content-font-right">今日</div>
          </div>
        </div>
        <div class="status-bottom-content">
          <div class="status-bottom-content-font">其他</div>
          <div class="status-bottom-content-number">
            <div class="status-bottom-content-number-left">
              {{num.t8}}</div>
            <div class="status-bottom-content-number-right">{{todayStu.t8}}</div>
          </div>
          <div class="status-bottom-content-font-bottom">
            <div class="status-bottom-content-font-left">总</div>
            <div class="status-bottom-content-font-right">今日</div>
          </div>
        </div>
      </div>
    </div>
    <!--    注册量总数 ----- 注册量身份   -->
    <div class="register">
      <!--      注册量总数-->
      <div class="registerSum">
        <div class="registerSum-top">
          <div class="registerSum-top-font">注册量 (总数)</div>
          <!--          时间选择器------日    -->
          <div v-show="tabTime1 == 1" class="registerSum-top-time">
            <a-date-picker
                @change="onChangeDay"
                format="YYYY-MM-DD"
                v-model="value"
                placeholder="开始时间"
                style="width: 42%"
            />
            -
            <a-date-picker
                @change="onChangeDay2"
                format="YYYY-MM-DD"
                v-model="value2"
                placeholder="结束时间"
                style="width: 42%"
            />
          </div>
          <!--          时间选择器------月    -->
          <div v-show="tabTime1 == 2" class="registerSum-top-time">
            <a-date-picker
                mode="year"
                placeholder="请选择年份"
                format='YYYY'
                v-model="value3"
                style="width: 85%"
                :open='yearShowTwo'
                @openChange="openChangeTwo"
                @panelChange="panelChangeTwo"/>
          </div>
          <!--          时间选择器------年    -->
          <div v-show="tabTime1 == 3" class="registerSum-top-time">
            <a-date-picker
                format="YYYY-MM-DD"
                v-model="value4"
                placeholder="选择时间"
                style="width: 85%"
                disabled
            />
          </div>

          <div class="registerSum-top-btn">
            <a-button @click="registerTab" data-id="1" class="registerSum-top-btn-content">日</a-button>
            <a-button @click="registerTab" data-id="2" class="registerSum-top-btn-content">月</a-button>
            <a-button @click="registerTab" data-id="3" class="registerSum-top-btn-content">年</a-button>
          </div>
        </div>
        <div class="register-font">
          注册趋势
        </div>
        <div v-show="tabTwo == 1" id="registerSumOne" style="height: 300px;width: 550px;margin-top: 20px;"></div>
        <div v-show="tabTwo == 2" id="registerSumTwo" style="height: 300px;width: 550px;margin-top: 20px;"></div>
        <div v-show="tabTwo == 3" id="registerSumThree" style="height: 300px;width: 550px;margin-top: 20px;"></div>
      </div>
      <!--      注册量身份-->
      <div class="registerStatus">
        <div class="registerStatus-top">
          <div class="registerStatus-top-font">注册量 (身份)</div>
        </div>
        <!--        注册身份----饼图-->
        <div style="height: 360px;width: 100%;display: flex">
          <div style="height: 360px;width: 85%;" id="registerBing">
          </div>
          <div class="status-bing">
            <div class="status-bing-right">
              <div class="status-bing-right-center">
                <div class="status-bing-right-font">
                  | {{Math.round((num.t1/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t2/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t3/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t4/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t5/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t6/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t7/registerNumber.count)*100 )+ '%'}}
                </div>
                <div class="status-bing-right-font">
                  | {{Math.round((num.t8/registerNumber.count)*100 )+ '%'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 15px;background-color: #f1f2f6;"></div>
    <!--    底层折线图 -->
    <div class="lineDiv">
      <!--      顶部-->
      <div class="lineDiv-top">
        <div class="lineDiv-top-font">注册量 (身份)</div>
        <div class="lineDiv-top-time">
          <!--        时间选择器-----日      -->
          <div v-show="tabTime2 == 1" class="lineDiv-top-time-content">
            <a-date-picker
                @change="onChangeTwoDay"
                format="YYYY-MM-DD"
                v-model="value5"
                placeholder="开始时间"
                style="width: 42%"
            />
            -
            <a-date-picker
                @change="onChangeTwoDay2"
                format="YYYY-MM-DD"
                v-model="value6"
                placeholder="结束时间"
                style="width: 42%"
            />
          </div>
          <!--        时间选择器-----月      -->
          <div v-show="tabTime2 == 2" class="lineDiv-top-time-content">
            <a-date-picker
                mode="year"
                placeholder="请选择年份"
                format='YYYY'
                v-model="value7"
                style="width: 85%"
                :open='yearShowOne'
                @openChange="openChangeOne"
                @panelChange="panelChangeOne"/>
          </div>
          <!--        时间选择器-----年      -->
          <div v-show="tabTime2 == 3"  class="lineDiv-top-time-content">
            <a-date-picker
                format="YYYY-MM-DD"
                v-model="value8"
                placeholder="开始时间"
                style="width: 85%"
                disabled
            />
          </div>
        </div>
        <!--        按钮   日月年-->
        <div class="lineDiv-top-btn">
          <a-button class="lineDiv-top-btn-content" @click="tabChange" data-id="1">日</a-button>
          <a-button class="lineDiv-top-btn-content" @click="tabChange" data-id="2">月</a-button>
          <a-button class="lineDiv-top-btn-content" @click="tabChange" data-id="3">年</a-button>
        </div>
      </div>
      <!--      折线图-->
      <div style="width: 100%">
        <div v-show="tab == 1" id="registerSunStaus" style="height: 430px;width: 1200px;"></div>
        <div v-show="tab == 2" id="registerSunStausTwo" style="height: 430px;width: 1200px;"></div>
        <div v-show="tab == 3" id="registerSunStausThree" style="height: 430px;width: 1200px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from "echarts";
import {
  getRegisterDay,
  getRegisterMoon, getRegisterStatusDay, getRegisterStatusMoon,
  getRegisterStatusSum, getRegisterStatusYear,
  getRegisterSum,
  getRegisterYear, getToday, getTodayReg
} from "../../service/home_page";

export default {
  data(){
    return{
      //当前时间
      nowDay:'',
      //七天前
      agoDay:'',
      //当前年份
      nowYear:'',
      //注册量总数---时间
      value:'',
      value2:'',
      value3:'',
      value4:'',
      value5:'',
      value6:'',
      //注册量身份---时间
      value7:'',
      value8:'',
      value9:'',
      value10:'',
      value11:'',
      value12:'',
      // tabOne:true,
      // tabTwo:false,
      // tabThree:false,

      // registerThree:false,
      // registerTwo:false,
      // registerOne:true,

      registerNumber:[],
      statusNumber:[],
      //8个身份数组
      num: {
        //医生
        t1: null,
        //护士
        t2: null,
        //医技
        t3: null,
        //药师
        t4: null,
        //学生
        t5: null,
        //其他医务从业者
        t6: null,
        //非其医务从业者
        t7: null,
        //其他
        t8:null
      },
      //8个身份每日注册量
      todayStu: {
        //医生
        t1: 0,
        //护士
        t2: 0,
        //医技
        t3: 0,
        //药师
        t4: 0,
        //学生
        t5: 0,
        //其他医务从业者
        t6: 0,
        //非其医务从业者
        t7: 0,
        //其他
        t8:0
      },
      staNumStu:[],
      todatCount:'',
      tab:1,
      tabTwo:1,
      tabTime1:1,
      tabTime2:1,

      trendDay:[],
      trendMoon:[],
      trendYear:[],

      trendStatusDay:[],
      trendStatusMoon:[],
      trendStatusYear:[],
      yearShowOne:false,
      yearShowTwo:false,
      }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"首页")
    this.getTime()
  },
  mounted() {
    this.trendDays()
    this.trendMoons()
    this.trendYears()
    this.trendStatusDays()
    this.trendStatusMoons()
    this.trendStatusYears()
    this.registerSum()
    this.StatusSum()
    this.todayNumber()
    this.todayData()
  },
  methods:{
    //获取时间
    getTime(){
      //当前时间
      this.nowDay = moment().format('YYYY-MM-DD')
      //七天前
      this.agoDay = moment().subtract(6,'days').format('YYYY-MM-DD')
      //获取年份
      this.nowYear = moment().format('YYYY')
      // console.log(this.agoDay,this.nowDay)
    },
    //注册量总数
    async registerSum(){
      const responce = await getRegisterSum()
        if(responce.code === 0){
          this.registerNumber = responce.data[0]
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
    },
    ////注册量身份总数
    async StatusSum(){
      const responce = await getRegisterStatusSum()
      if(responce.code === 0){
        this.statusNumber = responce.data
        const num = this.statusNumber
        // console.log(num)
        var newNum = num.map((ele,index) =>{
          // console.log(ele.count)
          // this.num = ele
          const nums = this.num
          switch(ele.type){
            case '1001':
              nums.t1 = ele.count
              break;
            case '1002':
              nums.t2 = ele.count
              break;
            case '1003':
              nums.t3 = ele.count
              break;
            case '1004':
              nums.t4 = ele.count
              break;
            case '1005':
              nums.t5 = ele.count
              break;
            case '1006':
              nums.t6 = ele.count
              break;
            case '1007':
              nums.t7 = ele.count
              break;
            default:
              break;
          }
        })
        this.num.t8 = this.registerNumber.count - (this.num.t1+this.num.t2+this.num.t3+this.num.t4+this.num.t5+this.num.t6+this.num.t7)
        // console.log('88888888'+this.num.t8)
        await this.rotationStus()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    //今日注册量总量
    async todayNumber(){
      const reponse = await getToday()
      if(reponse.code === 0){
        // console.log(reponse.data[0].count)
        this.todatCount = reponse.data[0]
      }
    },
    //注册量身份每日情况
    async todayData(){
      const reponse = await getTodayReg()
      if(reponse.code === 0){
        // console.log(reponse.data)
        this.staNumStu = reponse.data
        const num = this.staNumStu
        var newNum = num.map((ele,index) =>{
          const nums = this.todayStu
          switch(ele.type){
            case '1001':
              nums.t1 = ele.count
              break;
            case '1002':
              nums.t2 = ele.count
              break;
            case '1003':
              nums.t3 = ele.count
              break;
            case '1004':
              nums.t4 = ele.count
              break;
            case '1005':
              nums.t5 = ele.count
              break;
            case '1006':
              nums.t6 = ele.count
              break;
            case '1007':
              nums.t7 = ele.count
              break;
            default:
              break;
          }
        })
        const numberData = this.todayStu
        numberData.t8 = this.todatCount.count - (numberData.t1 + numberData.t2 + numberData.t3 + numberData.t4 + numberData.t5 + numberData.t6+ numberData.t7)
        // console.log(numberData)
      }else {
        this.$message.error('出错请联系管理员' + reponse.message)
      }
    },
    //注册量日
    async trendDays(){
      const start_time = this.agoDay
      const end_time = this.nowDay
      const responce = await getRegisterDay(start_time,end_time)
      if(responce.code === 0){
        this.trendDay = responce.data
        await this.drawLineOne()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    drawLineOne(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSumOne'));
      // 绘制图表
      myChart.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source:this.trendDay
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:
            {
              name:'注册量日',
              type:'line',
              smooth: true,
            },
      });
    },
    //注册量月
    async trendMoons(){
      const year = this.nowYear
      const responce = await getRegisterMoon(year)
      if(responce.code === 0){
        this.trendMoon = responce.data
        await this.drawLineTwo()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    drawLineTwo(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSumTwo'));
      // 绘制图表
      myChart.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source: this.trendMoon
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:
            {
              name:'注册量月',
              type:'line',
              smooth: true,
            },
      });
    },
    //注册量年
    async trendYears(){
      const responce = await getRegisterYear()
      if(responce.code === 0){
        this.trendYear = responce.data
        await this.drawLineThree()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    drawLineThree(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSumThree'));
      // 绘制图表
      myChart.setOption({
        legend: {},
        tooltip: {},
        dataset: {
          source: this.trendYear
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:
            {
              name:'注册量年',
              type:'line',
              smooth: true,
            },
      });
    },

    //注册量身份日
    async trendStatusDays(){
      const start_time = this.agoDay
      const end_time = this.nowDay
      const responce = await getRegisterStatusDay(start_time,end_time)
      if(responce.code === 0){
        this.trendStatusDay = responce.data
        // console.log('11111111111'+ JSON.stringify(this.trendStatusDay))
        await this.registerLine()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    registerLine(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSunStaus'));
      // 绘制图表
      myChart.setOption({
        legend: {
          top: '10%',
          left: '90%',
          right:'1%',
          itemGap:30,
          orient: 'vertical',
          y: 'center',
        },
        tooltip: {},
        dataset: [
          {
            source: this.trendStatusDay.t1001
          },
          {
            source: this.trendStatusDay.t1002
          },
          {
            source: this.trendStatusDay.t1003
          },
          {
            source: this.trendStatusDay.t1004
          },
          {
            source: this.trendStatusDay.t1005
          },
          {
            source: this.trendStatusDay.t1006
          },
          {
            source: this.trendStatusDay.t1007
          },
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'医生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name:'护士',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:2,
            name:'医技',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:3,
            name:'药师',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:4,
            name:'学生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:5,
            name:'其他医务从业者',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:6,
            name:'非医务从业者',
            type:'line',
            smooth: true,
          },
        ]

      });
    },

    //注册量身份月
    async trendStatusMoons(){
      const year = this.nowYear
      const responce = await getRegisterStatusMoon(year)
      if(responce.code === 0){
        this.trendStatusMoon = responce.data
        // console.log(JSON.stringify(this.trendStatusMoon))
        await this.registerLineTwo()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    registerLineTwo(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSunStausTwo'));
      // 绘制图表
      myChart.setOption({
        legend: {
          top: '10%',
          left: '90%',
          right:'1%',
          itemGap:30,
          orient: 'vertical',
          y: 'center',
        },
        tooltip: {},
        dataset: [
          {
            source: this.trendStatusMoon.t1001
          },
          {
            source: this.trendStatusMoon.t1002
          },
          {
            source: this.trendStatusMoon.t1003
          },
          {
            source: this.trendStatusMoon.t1004
          },
          {
            source: this.trendStatusMoon.t1005
          },
          {
            source: this.trendStatusMoon.t1006
          },
          {
            source: this.trendStatusMoon.t1007
          },
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'医生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name:'护士',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:2,
            name:'医技',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:3,
            name:'药师',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:4,
            name:'学生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:5,
            name:'其他医务从业者',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:6,
            name:'非医务从业者',
            type:'line',
            smooth: true,
          },
        ]

      });
    },

    //注册量身份年
    async trendStatusYears(){
      const responce = await getRegisterStatusYear()
      if(responce.code === 0){
        this.trendStatusYear = responce.data
        await this.registerLineThree()
      }else {
        this.$message.error('接口连接失败，请联系管理员' + responce.message)
      }
    },
    registerLineThree(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerSunStausThree'));
      // 绘制图表
      myChart.setOption({
        legend: {
          top: '10%',
          left: '90%',
          right:'1%',
          itemGap:30,
          orient: 'vertical',
          y: 'center',
        },
        tooltip: {},
        dataset: [
          {
            source: this.trendStatusYear.t1001
          },
          {
            source: this.trendStatusYear.t1002
          },
          {
            source: this.trendStatusYear.t1003
          },
          {
            source: this.trendStatusYear.t1004
          },
          {
            source: this.trendStatusYear.t1005
          },
          {
            source: this.trendStatusYear.t1006
          },
          {
            source: this.trendStatusYear.t1007
          },
        ],
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series:[
          {
            datasetIndex:0,
            name:'医生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:1,
            name:'护士',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:2,
            name:'医技',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:3,
            name:'药师',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:4,
            name:'学生',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:5,
            name:'其他医务从业者',
            type:'line',
            smooth: true,
          },
          {
            datasetIndex:6,
            name:'非医务从业者',
            type:'line',
            smooth: true,
          },
        ]

      });
    },


    //注册量总数1日，时间选择器
    async onChangeDay(val){
      this.value = moment(val).format('YYYY-MM-DD')
      const newVal = this.value
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value2 !== '' && this.value2 !== undefined && this.value2 !== null &&
          this.value2 > newVal){
        const start_time = this.value
        const end_time = this.value2
        const responce = await getRegisterDay(start_time,end_time)
        if(responce.code === 0){
          this.trendDay = responce.data
          await this.drawLineOne()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }
    },
    //注册量总数2日，时间选择器
    async onChangeDay2(val){
      this.value2 = moment(val).format('YYYY-MM-DD')
      const newVal = this.value2
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value !== '' && this.value !== undefined && this.value !== null &&
          newVal > this.value){
        const start_time = this.value
        const end_time = this.value2
        const responce = await getRegisterDay(start_time,end_time)
        if(responce.code === 0){
          this.trendDay = responce.data
          await this.drawLineOne()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }else {
        this.$message.warning('请选择有效日期！')
        this.value2 = ''
      }

    },

    //注册量身份2日，时间选择器
    async onChangeTwoDay(val){
      const ResigerData3 = moment(val).format('YYYY-MM-DD')
      this.value5 = ResigerData3
      const newVal = this.value5
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value6 !== '' && this.value6 !== undefined && this.value6 !== null &&
          this.value6 > newVal){
        const start_time = newVal
        const end_time = this.value6
        const responce = await getRegisterStatusDay(start_time,end_time)
        if(responce.code === 0){
          this.trendStatusDay = responce.data
          await this.registerLine()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }

    },
    //注册量注册量2日，时间选择器
    async onChangeTwoDay2(val){
      const ResigerData4 = moment(val).format('YYYY-MM-DD')
      this.value6 = ResigerData4
      const newVal = this.value6
      if(newVal !== '' && newVal !== undefined && newVal !== null &&
          this.value5 !== '' && this.value5 !== undefined && this.value5 !== null &&
          newVal > this.value5){
        const start_time = this.value5
        const end_time = newVal
        const responce = await getRegisterStatusDay(start_time,end_time)
        if(responce.code === 0){
          this.trendStatusDay = responce.data
          await this.registerLine()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }else {
        this.$message.warning('请选择有效日期！')
        this.value6 = ''
      }
    },

    rotationStus(){
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('registerBing'));
      // 绘制图表
      myChart.setOption( {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '15%',
          left: '76%',
          right:'1%',
          itemGap:20,
          orient: 'vertical',
          y: 'center',
        },
        // grid:{
        //   left:'0%',
        //   right:'0',
        //   bottom:'1%',
        //   containLabel:true
        // },
        series: [
          {
            name: '注册量（身份）',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'left'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.num.t1, name: '医生' },
              { value: this.num.t2, name: '护士' },
              { value: this.num.t3, name: '医技' },
              { value: this.num.t4, name: '药师' },
              { value: this.num.t5, name: '学生' },
              { value: this.num.t6, name: '其他医务从业者' },
              { value: this.num.t7, name: '非医务从业者' },
              { value: this.num.t8,name: '其他'},
            ]
          }
        ]
      });
    },


    //实现点击按钮切换内容
    tabChange(e){
      this.tab = e.target.dataset.id
      this.tabTime2 = e.target.dataset.id
    },
    registerTab(e){
      this.tabTwo = e.target.dataset.id
      this.tabTime1 = e.target.dataset.id
    },

    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status){
        this.yearShowOne =  true
      }
    },
    // 得到年份选择器的值
    async panelChangeOne(value) {
      this.value7 = moment(value).format('YYYY');
      this.yearShowOne = false;
      const newVal = this.value7
      if(newVal !== '' && newVal !== undefined && newVal !== null){
        const year = newVal
        const responce = await getRegisterStatusMoon(year)
        if(responce.code === 0){
          this.trendStatusMoon = responce.data
          // console.log(JSON.stringify(this.trendStatusMoon))
          await this.registerLineTwo()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }
    },

    // 弹出日历和关闭日历的回调
    openChangeTwo(status) {
      if (status){
        this.yearShowTwo =  true
      }
    },
    // 得到年份选择器的值
    async panelChangeTwo(value) {
      this.value3 = moment(value).format('YYYY');
      const newVal = this.value3
      this.yearShowTwo = false;
      if(newVal !== '' && newVal !== undefined && newVal !== null){
        const year = this.value3
        const responce = await getRegisterMoon(year)
        if(responce.code === 0){
          this.trendMoon = responce.data
          await this.drawLineTwo()
        }else {
          this.$message.error('接口连接失败，请联系管理员' + responce.message)
        }
      }
    }

  }
}
</script>

<style lang="scss">
.homePage{
  margin: -15px -15px ;
  width: 100%;
  //background-color: #f1f2f6;
  .admin{
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    .admin-left{
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      color: black;
      font-weight: bold;
      padding-left: 15px;
    }
    .admin-right{
      height: 50px;
      line-height: 50px;
      padding-right: 15px;
    }
  }
  .status{
    width: 100%;
    background-color: #f1f2f6;
    .status-top{
      width: 98%;
      height: 145px;
      background-color: #f1f2f6;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .status-top-content{
        width: 24%;
        height: 90%;
        background-color: #fff;
        .status-top-content-font{
          width: 100%;
          margin-top: 15px;
          margin-left: 15px;
          font-weight: bold;
          font-size: 14px;
        }
        .status-top-content-number{
          width: 100%;
          display: flex;
          margin-left: 15px;
          margin-top: 15px;
          font-size: 25px;
          .status-top-content-number-left{
            width: 30%;
            text-align: center;
          }
          .status-top-content-number-right{
            width: 70%;
            text-align: center;
          }

        }
        .status-top-content-font-bottom{
          width: 100%;
          margin-top: 5px;
          margin-left: 15px;
          font-size: 12px;
          display: flex;
          .status-top-content-font-left{
            width: 30%;
            text-align: center;
          }
          .status-top-content-font-right{
            width: 70%;
            text-align: center;
          }
        }
      }
    }
    .status-bottom{
      width: 98%;
      height: 145px;
      background-color: #f1f2f6;
      margin: auto;
      display: flex;
      justify-content: space-between;
      .status-bottom-content{
        width: 24%;
        height: 90%;
        background-color: #fff;
        .status-bottom-content-font{
          width: 100%;
          margin-top: 15px;
          margin-left: 15px;
          font-weight: bold;
          font-size: 14px;
        }
        .status-bottom-content-number{
          width: 100%;
          display: flex;
          margin-left: 15px;
          margin-top: 15px;
          font-size: 25px;
          .status-bottom-content-number-left{
            width: 30%;
            text-align: center;
          }
          .status-bottom-content-number-right{
            width: 70%;
            text-align: center;
          }

        }
        .status-bottom-content-font-bottom{
          width: 100%;
          margin-top: 5px;
          margin-left: 15px;
          font-size: 12px;
          display: flex;
          .status-bottom-content-font-left{
            width: 30%;
            text-align: center;
          }
          .status-bottom-content-font-right{
            width: 70%;
            text-align: center;
          }
        }
      }
    }
  }
  .register{
    width: 100%;
    height: 400px;
    background-color: #f1f2f6;
    display: flex;
    justify-content: space-around;
    .registerSum{
      width: 48%;
      height: 100%;
      background-color: #fff;
      .registerSum-top{
        height: 50px;
        border-bottom: 1px solid #e0dddd;
        display: flex;
        .registerSum-top-font{
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: black;
          height: 50px;
          line-height: 50px;
          width: 20%;
        }
        .registerSum-top-time{
          text-align: center;
          height: 50px;
          line-height: 50px;
          width: 50%;
        }
        .registerSum-top-btn{
          text-align: center;
          height: 50px;
          line-height: 50px;
          width: 30%;
          .registerSum-top-btn-content{
            margin-left: 5px;
          }
        }
      }
      //.registerSum-top-line{
      //  height: 300px;width: 100%;margin-top: 20px;
      //}
      .register-font{
        height: 50px;
        width: 200px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        line-height: 80px
      }
    }
    .registerStatus{
      width: 48%;
      height: 100%;
      background-color: #fff;
      .registerStatus-top{
        height: 50px;
        border-bottom: 1px solid #e0dddd;
        .registerStatus-top-font{
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          color: black;
          height: 50px;
          line-height: 50px;
          width: 20%;
        }

      }
    }
  }
  .lineDiv{
    width: 98%;
    margin: auto;
    height: 450px;
    background-color: #fff;
    .lineDiv-top{
      height: 50px;
      border-bottom: 1px solid #e0dddd;
      display: flex;
      .lineDiv-top-font{
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: black;
        height: 50px;
        line-height: 50px;
        width: 15%;
      }
      .lineDiv-top-time{
        width: 70%;
        .lineDiv-top-time-content{
          text-align: center;
          height: 50px;
          line-height: 50px;
          float: right;
        }
      }
      .lineDiv-top-btn{
        text-align: center;
        height: 50px;
        line-height: 50px;
        width: 15%;
        .lineDiv-top-btn-content{
          margin-left: 5px;
        }
      }
    }

  }
}
.status-bing{
  width: 15%;
  float: right;
  height: 100%;
  .status-bing-right{
    width: 100%;
    height: 360px;
    float: right;
    .status-bing-right-center{
      height: 250px;
      margin-top: 55px;
    .status-bing-right-font{
      text-align: center;
      margin-top: 15px;
      color: #aba9a9;
    }
    }
  }
}
</style>