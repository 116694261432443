import {get, post, put, del} from "@/service/base";
import {del_auth} from "./base";

/**
 * 获取招聘列表
 * @returns {Promise<unknown | void>}
 */
export function recruitmentList(data){
    return get("/api2/admin/recruitment",data)
}

/**
 * 通过id获取详情
 * @param r_id
 * @returns {Promise<* | void>}
 */
export function recruitmentListById(r_id){
    return get("/api2/admin/recruitment/"+r_id)
}

/**
 * 添加招聘列表
 * @param data 数据
 */
export function addRecruitment(data){
    return post("/api2/admin/recruitment",data)
}

/**
 * 修改招聘数据
 * @param data
 * @returns {Promise<* | void>}
 */
export function alterRecruitment(data){
    return put("/api2/admin/recruitment",data)
}

/**
 * 更新内容
 * @param data
 * @returns {Promise<* | void>}
 */
export function alterRecruitmentContent(data){
    return post("/api2/admin/updateRecruitmentContent",data)
}
/**
 * 医护人员管理    获取列表
 * @param type
 * @returns {*}
 */
export function getMedicalStaff(type,data){
    return get("/api/admin/auths/" + type,data)
}

/**
 * 医护人员管理   列表的搜索功能
 * @param type
 * @param data
 * @returns {*}
 */
export function getSearchStaff(type,data){
    return get("/api/admin/auths/" + type,data)
}

/**
 * 医护人员管理   添加用户
 * ,{headers:{'content-type':'application/json'}}
 */
export function postAdmin(type,data){
    return post("/api/admin/auth/" + type, data)
}

/**
 * 删除用户列表
 * @returns {*}
 * @param userIds
 */
export function delAdminList(userIds){
    return del("/api/admin/auth",{userIds:userIds})
}

/**
 * 修改用户密码
 * @param userIds
 * @returns {*}
 */
export function restPassWorld(userIds){
    return put("/api/admin/auth/auth_reset_password",userIds)
}

/**
 * 修改用户信息
 * @param userIds
 * @param data
 * @returns {*}
 */
export function restListDatas(userIds,data){
    return put("/api/admin/auth/" + userIds,data)
}

/**
 * 翻页功能
 * @param userIds
 * @param data
 * @returns {*}
 */
export function totalList(type,data){
    return get("/api/admin/auths/" + type, data)
}

export function adimnListCon(userIds){
    return get("/api/admin/auth/" + userIds)
}




