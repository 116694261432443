import axios from "axios";
import headerConfig from './api_header_config'

const header=headerConfig.api_header_epiboly
//封装get
export function get(url,params){
    return  axios.get(header+url,{
        params
    }).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function post(url,params){
    return  axios.post(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function put(url,params){
    return  axios.put(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
export function del(url,params){
    return  axios.delete(header+url,params
    ).then((res)=>{
        return res.data
    }).catch((e)=>{
        console.log(e)
    })
}
