//正式头
// const api_header = "https://yun-new-admin-api.yunicu.com";
const api_header = "https://yhf-admin-api.huizhirenxin.com";
//测试
// const api_header = "http://admin-dev-api.yunicu.com";

// 外包-正式
// const api_header_epiboly = "https://yunicu-api.yunicu.com";
const api_header_epiboly = "https://yhf-api.huizhirenxin.com";

// 外包测试
// const api_header_epiboly = "http://yunicu-api.we-fire.cn";

export default {
    api_header,api_header_epiboly
}

