//公共的api接口
import {post} from "@/service/base";

let user_id;

export function setUserId(value){
    user_id = value
}

/**
 * 上报埋点数据
 */
export function auth_data_report(modular,module_function,content_id){
    return post("/api/admin/auth/auth_data_report",{"user_id":user_id,modular,module_function,content_id})
}

/**
 * 登录
 */
export function login(login_name,password){
    return post("/api/admin/auth/login",{login_name,password})
}
