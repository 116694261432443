<template>
  <div style="margin: -15px -15px">
  <a-spin :spinning="spinning">
<div class="magazine">
<!--  标题搜索-->
  <div class="searchTable">
    标题 &nbsp; <a-input v-model="titleValue" placeholder="输入标题搜索" style="width: 210px" size="default" />
    <div style="margin-top:15px;">
      <a-button @click="onSearch" >查询</a-button>
      <a-button style="margin-left: 10px" @click="searchCancel" >重置</a-button>
    </div>
  </div>

  <a-tabs style="margin-top: 20px" type="card" @tabClick="callback" >
    <a-tab-pane key="1"  tab="已发布"></a-tab-pane>
    <a-tab-pane key="2" tab="已置顶"></a-tab-pane>
    <a-tab-pane key="3" tab="未发布"></a-tab-pane>
  </a-tabs>

  <div class="magazine_list">
<!--    杂志列表-->
    <!--   添加按钮-->
    <div style="display: flex; width: 150px;justify-content: space-between">
      <a-button type="primary" @click="()=>{this.visible = true}" style="margin-bottom: 10px" v-model="spinning">
        添加
      </a-button>
      <a-button @click="delMagazine">
        删除
      </a-button>
    </div>
  <a-table  :data-source="MagazineTable.rows" :columns="columns" rowKey="id" :pagination="pagination" @change="tableChange"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" >
    <span slot="table_title" slot-scope="text,record">
      <a-button @click="preview(record)" type="link" style="margin-left: -20px">
      {{record.title}}
    </a-button>
    </span>
    <span slot="condition" slot-scope="text,record">
      <span v-html="record.is_show === 0 ? '已发布':'未发布'">{{record.is_show}}</span>
    </span>
    <span style="display: flex;justify-content: space-between;"  slot="is_show" slot-scope="text, record">
      <a-button size="small" type="link" v-html="record.is_show === 1 ? '发布':'下架'" v-model="spinning" @click="change_show(record.id,record.is_show === 1? 0 : 1)">{{record.is_show}}</a-button>
      <a-button size="small" type="link" v-html="record.is_top === 0 ? '置顶':'取消置顶'" v-show="topBtn" @click="topChange(record.id,record.is_top === 1?0:1)">{{record.is_top}}</a-button>
<!--      <a-button  style="margin-left: 10px" size="small" type="primary" @click="update(record)">修改</a-button>-->
    </span>
  </a-table>

  </div>

<!--    =================弹框=======================-->
<!--    添加事件-->
    <a-modal
        title="添加"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        width="700px"
        @ok="addOk"

        ok-text="确认" cancel-text="取消"
    >
      <a-form-model :model="AddForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="addItem" >
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="AddForm.title"
          />
        </a-form-model-item>
        <a-form-model-item label="描述" prop="describe">
          <a-input v-model="AddForm.describe"  type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="显示日期" prop="show_date">
          <a-date-picker
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
              v-model="AddForm.show_date"
          />
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
          <div class="upload">
            <input class="upload_img" type="file" @change="upload" ref="upload_img" />
          </div>
          <div style="width: 150px;font-size: 12px;">请上传2M内的图片</div>
        </a-form-model-item>
        <a-card style="width: 300px;margin-left:110px">
          预览图:<img v-if="AddForm.image" style="width: 100px;height: 100px;" :src="AddForm.image" alt="form.image" />
        </a-card>
        <a-form-model-item style="margin-top:100px " label="跳转链接" prop="url">
          <a-input v-model="AddForm.url" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="AddForm.remark"  />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
<!--    修改事件-->
    <a-modal v-model="Update" title="修改"  width="700px" >
      <a-form-model
          :rules="rules"
          :model="UpdateForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem">
        <a-form-model-item label="标题" prop="title" >
          <a-input v-model="UpdateForm.title" />
        </a-form-model-item>
        <a-form-model-item label="描述" prop="describe">
          <a-input v-model="UpdateForm.describe" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="显示日期" prop="show_date">
          <a-date-picker
              format="YYYY-MM-DD"
              v-model="UpdateForm.show_date"
              type="date"
              placeholder="选择日期"
              style="width: 100%;"
          />
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
          <div class="upload">
            <input class="upload_img" type="file" @change="editImg" ref="editImg" />
          </div>
        </a-form-model-item>
        <a-card style="width: 300px;margin-left:110px;margin-bottom: 10px">
          预览图:<img v-if="UpdateForm.image" style="width: 100px;height: 100px;" :src="UpdateForm.image"  alt=""/>
        </a-card>
        <a-form-model-item label="跳转链接" prop="url">
          <a-input v-model="UpdateForm.url" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="UpdateForm.remark" />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click.stop="()=>{Update=false}">取消</a-button>
        <a-button type="primary" @click="editOk" v-model="spinning">确定</a-button>
      </template>
    </a-modal>
<!--    预览弹框-->
  <a-modal v-model="Preview" title="详情"  width="700px"  :footer="null"  >
    <a-descriptions title="" :column="1">
      <a-descriptions-item label="标题">
        {{PreviewForm.title}}
      </a-descriptions-item>
      <a-descriptions-item label="描述">
        {{PreviewForm.describe}}
      </a-descriptions-item>
      <a-descriptions-item label="显示日期">
        {{ PreviewForm.show_date}}
      </a-descriptions-item>
      <a-descriptions-item label="上传图片">
        <img style="width: 200px;height: 200px" :src="PreviewForm.image" alt="">
      </a-descriptions-item>
      <a-descriptions-item label="跳转链接">
        {{PreviewForm.url}}
      </a-descriptions-item>
      <a-descriptions-item label="备注">
        {{PreviewForm.remark}}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</div>
  </a-spin>
  </div>
</template>

<script>

import {
  delMagazines,
  getMagazine,
  getMagazineContent,
  postUpload,
  putEdit,
  putMagazine
} from '@/service/magazin_api'
import {update} from '@/utils/update'
import moment from "moment/moment";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

export default {
  name: "Magazine",
  data(){
    return{
      locale,
      show:0,
      isTop:0,
      user_id:'',
      topBtn:true,
      spinning:false,
      selectedRowKeys:[],
      //获取输入框内容
      titleValue:'',
      //表单验证
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }],
        url:[
          {required: true, message: '请输入地址', trigger: 'blur' }
        ],
        show_date:[
          { required: true, message: '请输入日期', trigger: 'change' }
        ],
        describe:[
          { required: true,message: '请输入描述', trigger: 'blur' }
        ],
        image:[
          { required: true, message: '需要上传图片',trigger: 'blur' }
        ],
      },
      // 添加,修改,预览弹框的隐藏
      visible: false,
      Update:  false,
      Preview: false,
      confirmLoading: false,
      // 医学杂志数据
      MagazineTable:[],
      // 分页器
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      count: 2,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      // 添加表单
      AddForm:{
        title: '',
        describe:'',
        image:'',
        url:'',
        show_date: '',
        remark: '',
      },
      // 修改表单
      UpdateForm:{
      },
      PreviewForm:{
      },
      // 表格表头
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '5%',
        },
        {
          title: '标题',
          width:'18%',
          scopedSlots: { customRender: 'table_title' }
        },
        {
          title: '描述',
          width: '15%',
          dataIndex: 'describe',
        },
        {
          title: '备注',
          dataIndex: 'remark',
        },
        {
          title: '显示时间',
          width: '10%',
          dataIndex: 'show_date',
        },
        {
          title: '状态',
          width: '8%',
          scopedSlots: { customRender: 'condition' },
        },
        {
          title: '操作',
          width: '8%',
          scopedSlots: { customRender: 'is_show' },
        },
      ],
    }
  },
  async created() {
    await this.$store.dispatch('setManageHeaderTitle', "医学杂志管理")
    await this.getMagazineList()
  },
  methods: {
    //获取杂志接口
     async getMagazineList(title,pageNumber){
       this.spinning= true
       const is_show = this.show
       const is_top = this.isTop
      const result=await getMagazine(title,is_show,is_top,pageNumber)
       if (result.code === 0){
         const  MagazineTable = result.data
         this.pagination.total = MagazineTable.count
         this.MagazineTable= MagazineTable
       }else {
         this.$message.error("获取错误，请联系管理员~"+result.message);
       }
       this.spinning=false
    },
    callback(activeKey){
      switch (activeKey){
        case '1':
          this.show = 0
            this.isTop = 0
          this.topBtn = true
          this.getMagazineList(this.titleValue)
          break;
          case '2':
            this.show = ''
              this.isTop = 1
            this.topBtn = true
            this.getMagazineList(this.titleValue)
            break;
        case '3':
          this.show = 1
            this.isTop = ''
          this.topBtn = false
          this.getMagazineList(this.titleValue)
          break;
        default:
          break;
      }
      },

    // 搜索框
    async onSearch(){
      // console.log(this.titleValue)
      const title = this.titleValue
      await this.getMagazineList(title)
      this.pagination.current = 1
    },
    // 搜索重置
    searchCancel(){
      this.titleValue = ''
      this.getMagazineList('')
    },
    // 分页器 参数   回调
    async tableChange(pageNumber){
      await this.getMagazineList(this.titleValue,pageNumber.current)
      this.pagination.current = pageNumber.current
    },
    //获取删除的id
    onSelectChange(selectedRowKeys){
      // //选择的列表的id
      this.user_id = selectedRowKeys
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    //删除按钮
    async delMagazine(){
       const ids = this.user_id
      const delId = await delMagazines(ids)
      if (delId.code === 0){
        await this.getMagazineList()
        this.$message.success("删除成功")
        this.user_id = ''
      }else {
        this.$message.error('出错请联系管理员！' + delId.message)
      }
      this.selectedRowKeys = []

    },

    // //点击添加按钮
    // add() {
    //   this.visible = true;
    // },
    //添加确认、取消按钮
    async addOk() {
       this.spinning=true
      this.$refs.addItem.validate(async valid =>{
        if (valid){
            const Upload = await postUpload(this.AddForm)
            if (Upload.code===0){
              //刷新列表
              await this.getMagazineList()
              //清空输入框
              this.$refs.addItem.resetFields();
              //关闭对话框
              this.visible= false;
            }
          } else {
            this.$message.error("输入有空~")
            return false;
        }
      })
      this.spinning = false
    },
    handleCancel() {
      // console.log('Clicked cancel button');
      this.visible = false;
    },
    //修改按钮
    async update(ref) {
      // this.UpdateForm = record
      // this.UpdateForm = JSON.parse(JSON.stringify(record))
      const id = ref.id
      const editId =await getMagazineContent(id)
      if (editId.code === 0){
        let UpdateForm=editId.data
        UpdateForm.show_date = moment(UpdateForm.show_date).format("YYYY-MM-DD")
        this.UpdateForm = UpdateForm
        this.Update = true;
      }else {
        this.$message.error('获取接口失败'+ editId.message);
      }
    },
    // 修改确认按钮
    editOk(){
      this.spinning = true
      this.$refs.editItem.validate(async valid =>{
        if (valid){
            let updateForm = this.UpdateForm
             updateForm.show_date = moment(updateForm.show_date).format("YYYY-MM-DD")
            const EditItem = await putEdit(updateForm)
            if (EditItem.code===0){
              await this.getMagazineList()
              this.Update = false;
              this.$refs.editItem.resetFields();
              this.$message.success('修改成功');
            }
            else {
              this.$message.error('修改失败'+ EditItem.message);
            }
          }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
      this.spinning = false
    },
    // 上传图片
    async upload(){
      let inputDOM = this.$refs.upload_img.files[0];
      if(inputDOM.size <2086666.24){
        const image =await update(inputDOM)
        if (image.code === 0){
          this.AddForm.image = image.data.url
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传2M内图片')
      }
    },
    // 修改图片
    async editImg(){
        let inputDOM = this.$refs.editImg.files[0];
      if(inputDOM.size <2086666.24){
        const EditImg =await update(inputDOM)
        console.log(EditImg.data.url)
        if (EditImg.code === 0){
          this.UpdateForm.image = EditImg.data.url
          this.$message.success('修改成功')
        }
        else {
          this.$message.warning('仅上传1M内图片'+ EditImg.message)
        }}
      else {
        this.$message.warning('仅上传2M内图片')
      }
    },


    // 预览弹框
    preview(row){
      this.PreviewForm = row
      this.Preview = true;
    },
    async topChange(id,is_top){
      this.spinning = true
      const data = {
        id:id,
        is_top: is_top
      }
      const response = await putMagazine(data);
      //判断是否成功
      if (response.code === 0){
        //重新获取列表，刷新列表
        await this.getMagazineList()
      }else {
        this.$message.warning('修改错误'+ response.message)
      }
      this.spinning = false
    },

    //显示与隐藏
    async change_show(id,is_show){
      this.spinning = true
      /**
       * is_show:0,1 0显示 1隐藏
       * @type {{id, is_show}}
       */

      const data = {
        id:id,
        is_show:is_show
      }
      //执行显示与隐藏
     const response = await putMagazine(data);
      //判断是否成功
      if (response.code === 0){
        //重新获取列表，刷新列表
        await this.getMagazineList()
      }else {
        this.$message.warning('修改错误'+ response.message)
      }
      this.spinning = false
    },
    },
}
</script>

<style lang="scss">
.magazine{
  background-color: whitesmoke;
}
.ant-tabs-bar {
  margin: 0 !important;
  border: none !important;
}
.btn{
  border-color:white !important;
  border-radius: 2px !important;
}
.searchTable{
  background-color: white;
  height: 100px;
  padding: 15px;
}
.magazine_list{
  background-color: white;
  padding: 15px;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.mask {
  background-color: #000;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}
.pop {
  background-color: #fff;
  position: fixed;
  top: 100px;
  left: 300px;
  width: calc(100% - 600px);
  height:calc(100% - 200px);
  z-index: 2
}
.upload_img{
  width: 90px;
  height: 50px;
  opacity: 0;
}
.upload{
  margin-top: 8px;
  width: 90px;
  height: 25px;
  background-image:url("../../assets/addImage.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
}

</style>