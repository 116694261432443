<template>
  <div style=" margin: -15px -15px;">
    <div class="titleTop">
      {{liveTitle}} - {{liveMeetName}}
    </div>
    <div class="liveMeet-top">
      <div class="liveMeet-top-input">
        <div>手机号:</div>
        <a-input
            v-model="titleData"
            class="input"
            placeholder="请输入手机号" />
      </div>
      <div class="liveMeet-top-input">
        <div>状态:</div>
        <a-radio-group
            style="margin-left: 10px"
            v-model="radioValue"
            @change="groupChange">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="0">
            未发放
          </a-radio-button>
          <a-radio-button value="1">
            已发放
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="liveMeet-top-input">
        <a-button
            @click="seachBtn"
            type="primary">查询</a-button>
        <a-button
            @click="resetBtn"
            style="margin-left: 10px">重置</a-button>
      </div>
      <div class="liveMeet-top-input">
        <a-button
            @click="exportBtn"
            style="margin-left: 10px">
          <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
          批量导出
        </a-button>
      </div>
    </div>
    <div style="width: 100%;">
      <a-table
          style="width: 95%;margin: auto;border-top: 1px solid #e5e3e3"
          :pagination="false"
          :rowKey="record=>record.id"
          :columns="columns"
          :data-source="dataOne">
        <span slot="status" slot-scope="text,record">
          {{text}}
        </span>
        <span slot="system" slot-scope="text,record">
          <a @click="openMadel(record.id)">发奖</a>
        </span>
        <span slot="release_status" slot-scope="text,record">
          <span v-if="text === 1">已发放</span>
          <span v-else>未发放</span>
        </span>
      </a-table>
      <!--          分页-->
      <div style="width: 100%;height: 50px;">
        <div style="float: right;margin-right: 20px;margin-top: 15px">
          <a-pagination
              @change="listChange"
              :total="listCount" />
        </div>
      </div>
    </div>
<!------------------------------对话框---------------------->
    <a-drawer
        title="发奖"
        placement="right"
        :closable="false"
        :visible="addVisible"
        width="500px"
        @close="()=>{this.addVisible = false;this.imageUrl = []}"
    >
      <div style="width: 450px;min-height: 800px">
          <div>领奖照片:</div>
        <div style="display: flex;width: 100%;">
          <div v-for="(item,index) in imageUrl"
               class="upload_image">
            <img
                style="width: 100%;height:auto;"
                :src="item"/>
          </div>
        </div>
        <div class="uploadImg" style="margin-left: 10px">
          <input
              style="width: 100px;height: 100px;opacity: 0;"
              accept="image/*"
              type="file"
              @change="upEditlistCover"
              ref="editThumbnail" />
        </div>
      </div>
      <div
          :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginRight: 8px" @click="()=>{this.addVisible = false;this.imageUrl = []}">
          取消
        </a-button>
        <a-button type="primary" @click="submit">
          提交
        </a-button>
      </div>
    </a-drawer>

    <div style="height: 50px"></div>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {update} from "../../utils/update";
import {getRaffle, putRaffle} from "../../service/liveMeet_api";
import axios from "axios";
import common from "@/common";
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '手机号',
    dataIndex: 'tel',
    key: 'tel',
    width:'13%',
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: '地址',
    dataIndex: 'receiving_address',
    key: 'receiving_address',
  },
  {
    title: '中奖时间',
    dataIndex: 'created_time',
    key: 'created_time',
  },
  {
    title: '状态',
    dataIndex: 'release_status',
    key: 'release_status',
    scopedSlots: { customRender: 'release_status' },
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    scopedSlots: { customRender: 'system' },
  },
];
const dataOne = [
];
export default {
  data(){
    return{
      listCount:30,
      addVisible:false,
      dataOne,
      columns,
      titleData:'',
      radioValue:'',
      imageUrl:[
      ],
      page_no:'',
      page_size:'',
      listId:'',
    }
  },
  created() {
    this.getList()
  },
  computed:{
    liveTitle(){
      return this.$store.state.liveTitle
    },
    liveMeetName(){
      return this.$store.state.liveMeetName
    },
  },
  methods:{
    //获取列表
    async getList(page_no,page_size,release_status,tel) {
      const response = await getRaffle(this.$route.query.id,page_no,page_size,release_status,tel)
      if (response.code === 0) {
        this.dataOne = response.data.rows
        this.listCount = response.data.count
      } else {
        this.$message.warning("失败，请联系管理员", response.message)
      }
    },
    returnBtn(){
      window.history.back()
    },
    groupChange(value){
      // console.log(value)
    },
    seachBtn(){
      this.getList(this.page_no,this.page_size,this.radioValue,this.titleData)
    },
    resetBtn(value){
      this.titleData = ''
      this.radioValue = ''
    },
    exportBtn(){
      axios.get('https://yun-new-admin-api.yunicu.com/api/admin/export/raffleWinnings',
          {
            params:{"activity_id":this.$route.query.id, "tel":this.titleData, "release_status":this.radioValue},
            responseType:'blob'
          }
      ).then((res)=>{
        if(!res)return
        let blob = new Blob([res.data],{type:'application/vnd.ms-excel;charset=utf8'})
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob);//创建下载的链接
        downloadElement.href = href;
        downloadElement.download = '中奖名单';//下载后文件名
        // downloadElement.download =decodeURIComponent(
        //     res.headers['content-disposition'].split(";")[1].split("filename=")[1]); // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click();//点击下载
        document.body.removeChild(downloadElement);//下载完成移除元素
        window.URL.revokeObjectURL(href);//释放掉blob对象
      }).catch((res)=>{
        console.log(res)
      })
    },
    async submit() {
        const data = {
          "release_status":"1",
          "list":this.imageUrl
        }
        let id = this.listId
        const response = await putRaffle(id,data)
        if (response.code === 0) {
          this.addVisible = false
          this.$message.success("发奖成功！")
          await this.getList(this.page_no, this.page_size, this.radioValue, this.titleData)
          //清空图片
          this.imageUrl = []
        } else {
          this.$message.warning("失败，请联系管理员", response.message)
        }
    },
    openMadel(id){
      this.addVisible = true
      this.listId = id
    },
    async upEditlistCover(index) {
      let inputDOM = this.$refs.editThumbnail.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.imageUrl.push(image.data.url)
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500KB的图片！")
      }
    },
    listChange(page_no,page_size){
      this.page_no = page_no
      this.page_size = page_size
      this.getList(page_no,page_size,this.radioValue,this.titleData)
    },

  }
}
</script>

<style lang="scss" scoped>
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 33%;
  height: auto;
  margin-left: 10px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.titleTop{
  height: 50px;
  border-bottom: 10px solid #f5f5f5;
  line-height: 40px;
  font-weight: bold;
  color: black;
  padding-left: 15px;
}
.liveMeet-top{
  width: 100%;
  height: 85px;
  border-bottom: 15px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
.liveMeet-top-input{
  display: flex;
  justify-content: center;
  width: 25%;
  height: 50px;
  line-height: 30px;
  padding-top: 20px;
.input{
  width: 70%;
  margin-left: 10px;
}
}
}
</style>