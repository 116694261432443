import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    manageHeaderTitle:"云ICU后台管理系统",
    returnId:'',
    condition:'',
  //  通过的审批按钮
    passBtn:false,
  //  审核的模板链接
    caseLink:'',
    //图片打码左侧id
    returnLeftId:'',
    //图片打码状态
    picState:'',
  //  直播抽奖活动标题
    liveTitle:'',
  //  直播活动会议名称
    liveMeetName:'',
  //  直播间新增里的两张图片默认打开显示会议的图片
    liveImg:{},
  // 病例审核页码数
    pageNo:1,
  },
  mutations: {
    setManageHeaderTitle(state,value){
      state.manageHeaderTitle = value
    },
    //病例返回上一层恢复历史状态
    returnId(state,data){
      state.returnId = data
    },
    condition(state,data){
      state.condition = data
    },
    passBtn(state,data){
      state.passBtn = data
    },
    caseLink(state,data){
      state.caseLink = data
    },
    //图片打码返回上一层恢复历史状态
    returnLeftId(state,data){
      state.returnLeftId = data
    },
    picState(state,data){
      state.picState = data
    },
    liveMeetName(state,data){
      state.liveMeetName = data
    },
    liveTitle(state,data){
      state.liveTitle = data
    },
    liveImg(state,data){
     state.liveImg = data
    },
    pageNo(state,data){
     state.pageNo = data
    },
  },
  actions: {
    setManageHeaderTitle(context,value){
      context.commit("setManageHeaderTitle",value)
    },
    returnId(context,data){
      context.commit('returnId',data)
    },
    condition(context,data){
      context.commit('condition',data)
    },
    passBtn(context,data){
      context.commit('passBtn',data)
    },
    caseLink(context,data){
      context.commit('caseLink',data)
    },
    returnLeftId(context,data){
      context.commit('returnLeftId',data)
    },
    picState(context,data){
      context.commit('picState',data)
    },
    liveTitle(context,data){
      context.commit('liveTitle',data)
    },
    liveMeetName(context,data){
      context.commit('liveMeetName',data)
    },
    liveImg(context,data){
      context.commit('liveImg',data)
    },
    pageNo(context,data){
      context.commit('pageNo',data)
    },

  },
  modules: {
  }
})
