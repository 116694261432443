/**
 * name:lcg
 * data:2021年12月16日
 */
import {get, put, post, del} from "./base";
//获取医学杂志
export function getMagazine(title,is_show,is_top,page_no){
    return get('/api/admin/magazine',{"page_size":10,title,is_show,is_top,page_no})
}
export function getMagazineContent(id){
    return get('/api/admin/magazine/' +id)
}

// 显示隐藏按钮
export function putMagazine(data){
    return put('/api/admin/magazine',data)
}
// export  function postUpload(file){
//     return post('/api/admin/common/update',file,{headers:{'Content-Type':'multipart/form-data'}})
// }
// 添加表单
export function postUpload(data){
    return post('/api/admin/magazine',data)
}
// 修改表单
export function putEdit(data){
    return put('/api/admin/magazine',data)
}
export function delMagazines(ids){
    return del('/api/admin/magazine',{ids})
}