<template>
  <div class="footer">
    <div class="title">
      <span>{{ manageHeaderTitle }}</span>
    </div>
    <div class="info">
      <div class="header">
        <a-dropdown
          placement="bottomCenter">
          <a-avatar
              shape="square"
              size="default"
              :style="{ backgroundColor:'#1890ff' , verticalAlign: 'middle' }"
          >
            {{ user_name }}
          </a-avatar>
          <a-menu slot="overlay">
            <a-menu-item>
              个人信息
            </a-menu-item>
            <a-menu-item @click="log_out">
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageHeader",
  data(){
    return{

    }
  },
  computed:{
    user_name(){
      const user_info = window.localStorage.getItem("user_info");
      const user_name =  JSON.parse(user_info).user_name
      return user_name.slice(user_name.length-1)
    },
    manageHeaderTitle(){
      return this.$store.state.manageHeaderTitle
    }
  },
  methods:{
  //  退出登录
    log_out(){
      this.$router.push("/login")
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  height: 64px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title{
    font-size: 15px;
    line-height: 64px;
    font-weight: 800;
    padding: 0 24px;
  }
  .info{
    display: flex;
    font-size: 15px;
    line-height: 64px;
    padding: 0 24px;
    .header{
      padding: 0 24px;
    }
  }
}
</style>