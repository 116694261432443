<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed"
                    :trigger="null"
                    collapsible
                    breakpoint="xl"
                    class="sider"
    >
      <manage-sider :collapsed="collapsed"/>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="height:64px;background: #fff; padding: 0;display: flex">
        <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
        />
        <ManageHeader></ManageHeader>
      </a-layout-header>
      <a-layout-content
          :style="{ margin: '15px 16px', padding: '15px', background: '#fff', minHeight: '280px',
            overflowY:'scroll'}"
      >
        <router-view style="overflow-y: hidden"></router-view>
<!--        <div style="position:absolute;bottom: 15px">-->
<!--            云ICU后台管理系统©2021 Created by 优屏研发团队-->
<!--        </div>-->
      </a-layout-content>
      <a-layout-footer style="height:35px;text-align: center;padding: 0;">
        <ManageFooter></ManageFooter>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import ManageSider from "@/components/ManageSider";
import ManageHeader from "@/components/ManageHeader";
import ManageFooter from "@/components/ManageFooter";
export default {
  name: "ManageRoot",
  components: {ManageFooter, ManageHeader, ManageSider},
  data(){
    return{
      collapsed: false,
    }
  }
}
</script>

<style>
.ant-layout{
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.sider{
  overflow-y: scroll;
}
</style>