import{get}from"./base";
//注册总量
export function getRegisterSum(){
    return get('/api2/registerNumberTotal')
}
//注册量身份总数
export function getRegisterStatusSum(){
    return get('/api/totalRegisterUser')
}
//今日注册量
export function getToday(){
    return get('/api2/registerNumberTotalThisYear')
}
//注册量每日人数
export function getTodayReg(){
    return get('/api/totalRegisterUserToday')
}
// 注册量（日）:registerNumberTotalDay
export function getRegisterDay(start_time,end_time){
    return get('/api2/registerNumberTotalDay',{"start_time":start_time,"end_time":end_time})
}
// 注册量（月）:registerNumberTotalMonth
export function getRegisterMoon(year){
    return get('/api2/registerNumberTotalMonth',{"year":year})
}
// 注册量（年）:registerNumberTotalYear
export function getRegisterYear(){
    return get('/api2/registerNumberTotalYear')
}


//注册量身份---日
export function getRegisterStatusDay(start_time,end_time){
    return get('/api/registerUserSelectDay',{"start_time":start_time,"end_time":end_time})
}
//注册量身份---月
export function getRegisterStatusMoon(year){
    return get('/api/registerUserSelectMoon',{"year":year})
}
//注册量身份---年
export function getRegisterStatusYear(){
    return get('/api/registerUserSelectYear')
}