<template>
<div class="document">
  <a-spin :spinning="spinning">
    <div class="seachTitle">
      <a-input
        v-model="valueTitle"
        placeholder="请输入标题"
        @pressEnter="enterBtn"
        style="width: 30%;"/>
      <div style="margin-top: 15px">
        <a-button @click="onSearch">查询</a-button>
        <a-button style="margin-left: 15px" @click="resetBtn">重置</a-button>
      </div>
    </div>
    <div style="background-color:#f5f5f5;">
      <a-tabs type="card" @tabClick="selectState">
        <a-tab-pane key="1" tab="已发布">
        </a-tab-pane>
        <a-tab-pane key="2" tab="已置顶">
        </a-tab-pane>
        <a-tab-pane key="3" tab="未发布">
        </a-tab-pane>
      </a-tabs>
    </div>
    <div style="padding: 15px 15px;position: relative;background-color: #fff;">
      <div>
          <!--  添加按钮-->
          <div class="document-btn">
            <a-button style="width: 80px" type="primary" @click="showModal()">新增</a-button>
            <a-popconfirm style="margin-left: 15px" placement="top" ok-text="是" cancel-text="否" @confirm="deleteList">
              <template slot="title">
                <p>是否确认删除？</p>
              </template>
              <a-button>删除</a-button>
            </a-popconfirm>
          </div>
          <!--  文献列表-->
          <!--:columns标题 :data-source数据 :pagination只显示十行 :rowKey添加key record 为每一条数据, index 索引-->
          <div class="document-content">
            <a-table
                :columns="columns"
                :data-source="documentList"
                :rowKey='record=>record.id'
                :pagination="false"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
              <!--  状态  0表示显示  1表示不显示  -->
              <span slot="isShow" slot-scope="text, record">
                {{record.is_show === 0 ?'已发布':'未发布'}}
              </span>
              <span slot="title_link" slot-scope="text, record">
                  <a @click="reviseXiu(record.id)">{{record.title}}</a>
              </span>
              <!--      按钮的显示与隐藏-->
              <span slot="is_show" slot-scope="text,record,scope" style="display: flex;justify-content: space-around;">
              <a size="small" @click="dian_btn(record.id,record.is_show === 1?0:1 )">{{record.is_show === 1?'发布':'下架'}}</a>
                <!--        修改按钮-->
<!--              <a-button type="primary" size="small" @click="reviseXiu(record.id)" style="margin-left: 15px">修改</a-button>-->

              <a v-show="is_topBtn" @click="topBtn(record.id,record.is_top === 1?0:1)">{{record.is_top === 1?'取消置顶':'置顶'}}</a>
              </span>
            </a-table>
            <div style="width: 100%;height: 50px;">
              <div style="float: right;margin-right: 20px;margin-top: 15px">
                <a-pagination
                    @change="selectPage"
                    :total="count" />
              </div>
            </div>
          </div>
      </div>

  <!--  ======================================对话框====================================-->
  <!--  添加对话框-->
    <a-modal v-model="visible" title="添加"
             @ok="handleOk"
             cancelText="关闭"
    class="addVisible">
      <!--  添加的表单界面  -->
      <a-form-model
          ref="ruleForm"
          :model="AddForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" label="标题" prop="title">
          <a-input v-model="AddForm.title"/>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="describe">
          <a-input v-model="AddForm.describe" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="显示时间" prop="show_date">
          <a-date-picker
              placeholder="Pick a date"
              style="width: 100%;"
              v-model="AddForm.show_date"
          />
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
              <div class="add-image">
                <input
                    type="file"
                    @change="addImage"
                    ref="inputer"
                    class="imageAdd"
                    accept="image/*"
                />
                <div style="width: 150px;font-size: 12px">请上传小于1M的图片</div>
              </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-card class="imageCard" >
            预览图：
            <img style="max-width: 100px;" v-if="AddForm.image" :src="AddForm.image">
          </a-card>
        </a-form-model-item>
        <a-form-model-item label="上传文件" prop="url">
            <div class="add-file">
              <input
                  type="file"
                  @change="addFile"
                  ref="inputFile"
                  class="fileAdd"
                  accept=".pdf"
              />
              <div style="width: 150px;font-size: 12px">请上传pdf格式的文件</div>
            </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-card class="fileCard">
            <div>上传地址:{{AddForm.url}}</div>
          </a-card>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="AddForm.remark"></a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="addSubmit()">
            添加
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetForm">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
  <!--    去除a-model自带的取消和确认-->
      <div slot="footer"></div>
    </a-modal>

  <!--  修改对话框-->
    <a-modal
        title="修改"
        :visible="visibleAdd"
        @change="handleCancel"
        class="modFrom"
    >
      <a-form-model
          ref="ruleFormXiu"
          :model="ticketInfoform"
          :rules="rules"
          :label-col="labelColXiu"
          :wrapper-col="wrapperColXiu"
      >
        <a-form-model-item ref="name" label="标题" prop="title">
          <a-input v-model="ticketInfoform.title"/>
        </a-form-model-item>

        <a-form-model-item label="描述" prop="describe">
          <a-input v-model="ticketInfoform.describe" type="textarea" />
        </a-form-model-item>

        <a-form-model-item label="显示日期" prop="show_date">
          <a-date-picker
              placeholder="请选择时间"
              style="width: 100%;"
              v-model="ticketInfoform.show_date"
          />
        </a-form-model-item>
        <a-form-model-item label="上传图片" prop="image">
          <div class="mod-image">
            <input
                type="file"
                @change="xiuImage"
                ref="xiuInput"
                class="imageMod"
                accept="image/*"
            />
            <div style="width: 150px;font-size: 12px">请上传小于1M的图片</div>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-card class="modImage" >
            预览图：
            <img style="max-width: 100px;" :src="ticketInfoform.image">
          </a-card>
        </a-form-model-item>
        <a-form-model-item label="上传文件" prop="url" >
          <div class="mod-file">
            <input
                type="file"
                @change="xiuFile"
                ref="xiuInputer"
                class="fileMod"
                accept=".pdf"
            />
            <div style="width: 150px;font-size: 12px">请上传pdf格式的文件</div>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-card class="modFile">
            <div>上传地址:{{ticketInfoform.url}}</div>
          </a-card>
        </a-form-model-item>

        <a-form-model-item  label="备注">
          <a-input v-model="ticketInfoform.remark"/>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="XiuSubmit()">
            确定
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetFormXiu">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <!--    去除a-model自带的取消和确认-->
      <div slot="footer"></div>

    </a-modal>
<!--  &lt;!&ndash;    详情对话框&ndash;&gt;-->
<!--    <a-modal-->
<!--        :visible="row_content"-->
<!--        @cancel="content_close"-->
<!--        class="modFrom"-->
<!--    >-->
<!--      <a-descriptions title="详情">-->
<!--        <a-descriptions-item label="标题" :span="3">-->
<!--          {{ticketInfoform.title}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="描述" :span="3">-->
<!--          {{ticketInfoform.describe}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="显示日期" :span="3">-->
<!--          {{ticketInfoform.show_date}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="上传图片" :span="3">-->
<!--          <img style="width: 200px;height: auto" :src="ticketInfoform.image"/>-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="上传文件" :span="3" >-->
<!--          {{ticketInfoform.url}}-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item label="备注" :span="3">-->
<!--          {{ticketInfoform.remark}}-->
<!--        </a-descriptions-item>-->
<!--      </a-descriptions>-->
<!--      &lt;!&ndash;    去除a-model自带的取消和确认&ndash;&gt;-->
<!--      <div slot="footer"></div>-->

<!--    </a-modal>-->
    </div>
  </a-spin>
</div>
</template>

<script>
import {
  delList,
  getDocument,
  getDocumentContent, getDocumentTitle,
  postDocument,
  putDocument,
  putDocumentContent
} from "../../service/document_api";
import {info} from "sass";
import {update,update_File} from "../../utils/update";
import moment from "moment";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    align:'center',
    ellipsis: true,
    scopedSlots: { customRender: 'title_link' },
  },
  {
    title: '描述',
    dataIndex: 'describe',
    width: '20%',
    align:'center',
    key: 'describe',
  },
  {
    title: '创建时间',
    dataIndex: 'show_date',
    key: 'show_date',
    width: '10%',
    align:'center',
  },
  {
    title: '状态',
    dataIndex: 'is_show',
    key: 'state',
    width: '10%',
    align:'center',
    scopedSlots: { customRender: 'isShow' },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: '10%',
    align:'center',
  },
  {
    title: '操作',
    dataIndex: 'is_show',
    key: 'is_show',
    width: '12%',
    align:'center',
    scopedSlots: { customRender: 'is_show' },
  },
];
//点击修改按钮上传图片，创造全局的getBase64函数
async function getBase64(originFileObj) {

}
export default {
  name: "Document",
  data(){
    return{
      is_topBtn:true,
      isTop:0,
      count:0,
      user_id:'',
      showBtn:0,
      selectedRowKeys:[],
      spinning:false,
      valueTitle:'',
      //标题
      titleList:[],
      //获取文献列表详情
      documentContent:[],
      documentList:[],
      columns,
      show:true,
      //默认不显示添加表单
      visible: false,
      //默认不显示修改表单
      visibleAdd:false,
      //默认不显示
      // row_content:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      labelColXiu: { span: 4 },
      wrapperColXiu: { span: 14 },
      //添加
      AddForm: {
        title:'',
        describe:'',
        show_date:'',
        image:'',
        url:'',
        remark: ''
      },
      rules: {
        title: [{required: true, message: '请填写标题', trigger: 'blur'},],
        show_date: [{required: true, message: '请选择时间', trigger: 'change'}],
        describe: [{required: true, message: '请填写描述', trigger: 'blur'}],
        remark:[{ trigger: 'blur'}],
        image:[{required: true, message: '请填写备注', trigger: 'blur'}],
        url:[{required: true, message: '请填写备注', trigger: 'blur'}]
      },
      ticketInfoform: {
        //标题
        // name: '',
        title:'',
        //显示日期
        show_date: '',
        image:'',
        url:'',
        is_show:'',
        //描述
        describe: '',
        //备注
        remark:''
      },
      previewImage: '',

    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"文献指南管理")
  },
  mounted() {
    //获取接口列表
    this.getDocument()
  },
  methods: {
    //获取接口列表
    async getDocument(titleName,page){
      this.spinning = true
      //获取接口
      const is_show = this.showBtn
      const is_top = this.isTop
      const documentLists = await getDocument(is_show,titleName,page,10,is_top)
      //判断接口的code是否为0
      if(documentLists.code === 0){
        //将获取到的列表赋值
        this.documentList = documentLists.data.rows
        this.count = documentLists.data.count
      }else {
        //出错误的话报错
        this.$message.warning('出错，请联系管理员'+ documentLists.message);
      }
      this.spinning = false
    },
    async selectPage(page){
      await this.getDocument('',page)
    },
    selectState(activeKey){
      const titleName = this.valueTitle
      switch (activeKey){
        //已发布   搜索---显示且未置顶
        case '1':
          this.showBtn = 0
          this.isTop = 0
          this.is_topBtn = true
          this.getDocument(titleName)
              break;
        //已置顶  搜索---已置顶
        case '2':
          this.showBtn = ''
          this.isTop = 1
          this.is_topBtn = true
          this.getDocument(titleName)
          break;
        //未发布  搜索---未发布的，就是隐藏的
        case '3':
          this.showBtn = 1
            this.isTop = ''
          //  不显示置顶，取消置顶按钮
            this.is_topBtn = false
          this.getDocument(titleName)
          break;
        default:
          break;
      }
    },
    //添加上传图片
    async addImage(){
        let files = this.$refs.inputer.files[0];
        if(files !== undefined){
          if (files.size<2086666){
            let newVar = await update(files);
            if(newVar.code === 0){
                this.AddForm.image = newVar.data.url
                this.$message.success('图片上传成功')
              }else {
              this.$message.warning("上传失败！"+ newVar.$message)
              }
          }else {
            this.$message.warning('请上传小于2M的图片')
          }
       }
    },
    //添加上传文件
    async addFile(){
      let file = this.$refs.inputFile.files[0];
      if (file !== undefined){
        let newVar = await update_File(file);
        this.AddForm.url = newVar.data.url
          if(newVar.code === 0){
            this.AddForm.url = newVar.data.url
            this.$message.success('文件上传成功')
          }else {
            this.$message.error('上传文件失败'+ newVar.message)
          }
      }
    },

    //添加的确认按钮
    async addSubmit() {
      //判断表单中的内容不为空
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
            //调取post接口
            const documentPostLists = await postDocument(this.AddForm)
            if (documentPostLists.code === 0) {
              //刷新界面
              await this.getDocument();
              //清空界面
              this.$refs.ruleForm.resetFields();
              //关闭表单
              this.visible = false
              this.$message.success('添加成功');
            } else {
              this.$message.warning('出错，请联系管理员' + documentPostLists.message);
            }
          }  else {
          this.$message.error("表单有空，请填写或选择！")
          return false;
        }
      });
    },
    //添加的取消按钮
    resetForm() {
      //关闭表单
      this.visible = false
    },
    // 修改中点击❌关闭弹框
    handleCancel(e) {
      this.visibleAdd = false;
    },

    //修改上传图片
    async xiuImage(file){
      const formXiu = this.ticketInfoform
      let files = this.$refs.xiuInput.files[0];
      if (files!==undefined){
        if(files.size<2086666){
        let newVar = await update(files);
        if(newVar.code === 0){
          // this.form.image = newVar.data.url
          formXiu.image = newVar.data.url
          this.$message.success('图片上传成功')
        }else {
          console.log("上传失败",newVar.message)
        }
      }else {
          this.$message.warning('请上传小于2M的图片')
        }
      }
    },
    //添加上传文件，限制pdf格式
    async xiuFile(){
      const formXiu = this.ticketInfoform
      let file = this.$refs.xiuInputer.files[0];
      if (file!==undefined){
        let newVar = await update_File(file);
        if(newVar.code === 0){
          formXiu.url = newVar.data.url
          this.$message.success('文件上传成功')
        }else {
          console.log("上传失败",newVar.message)
        }
      }
    },

    //修改,点击修改按钮弹出修改表单,获取整行的信息
    async reviseXiu(id){
      this.spinning = true
      //1.点击修改按钮获取当前行的数据
      const response = await getDocumentContent(id)
      if(response.code === 0){
        let ticketInfoform = response.data
        ticketInfoform.id = id
        ticketInfoform.show_date = moment(ticketInfoform.show_date).format("YYYY-MM-DD")
        this.ticketInfoform = ticketInfoform
        this.visibleAdd = true
      }else {
        this.$message.error('获取接口失败'+ response.message);
      }
      this.spinning = false
    },
    //修改的确认按钮
    XiuSubmit() {
      this.$refs.ruleFormXiu.validate(async valids => {
        if (valids) {
            //调取put接口
            const form = this.ticketInfoform;
            form.show_date = moment(form.show_date).format("YYYY-MM-DD")
            const documentPutLists = await putDocumentContent(form)
            if (documentPutLists.code === 0) {
              //刷新界面
              await this.getDocument();
              this.visibleAdd = false
              this.$message.success('修改成功');
            } else {
              this.$message.warning('出错，请联系管理员' + documentPutLists.message);
            }
          } else {
          this.$message.error("表单有空，请填写或选择！")
          return false;
        }
      });

    },
    //修改的取消按钮
    resetFormXiu() {
      this.visibleAdd = false;
    },
    //修改点击按钮，显示与隐藏
    async dian_btn(id,is_show){
      this.spinning = true
      const data = {
        id:id,
        is_show:parseInt(is_show)
      }
      //调取put接口,执行修改
      const documentPutLists = await putDocument(data)
      //判断code执行成功
      if(documentPutLists.code === 0){
        //重新获取列表，刷新界面
        await this.getDocument();
      }else {
        this.$message.warning('出错，请联系管理员'+ documentPutLists.message);
      }
      this.spinning = false
    },
    //制定与下架
    async topBtn(id,is_top){
      const data = {
        id:id,
        is_top:parseInt(is_top)
      }
      //调取put接口,执行修改
      const documentPutLists = await putDocument(data)
      //判断code执行成功
      if(documentPutLists.code === 0){
        //重新获取列表，刷新界面
        await this.getDocument();
      }else {
        this.$message.warning('出错，请联系管理员'+ documentPutLists.message);
      }
    },
    //点添加按钮显示表单
    showModal() {
      this.spinning = true
      this.visible = true;
      this.spinning = false
    },
    //删除列表
    async deleteList(){
      const userIds = this.user_id
      if(userIds !== '' && userIds !== null && userIds !== undefined){
        const response = await delList(userIds)
        if(response.code === 0){
          this.$message.success("删除成功")
          //刷新列表
          await this.getDocument()
          //清空user_id
          this.user_id = ''
        }else {
          this.$message.error('出错请联系管理员！' + response.message)
        }
        //清空
        this.selectedRowKeys = []
      }else {
        this.$message.warning("请选择需要删除的列表！")
      }
    },
    //获取id
    onSelectChange(selectedRowKeys){
      // //选择的列表的id
      this.user_id = selectedRowKeys
      // //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    //添加中点击❌关闭弹框
    handleOk(e) {
      this.visible = false;
    },
    // 点击搜索按钮搜索相应内容
    async onSearch() {
      this.showBtn = ''
      //获取输入框中的内容，并赋值
      const titleName = this.valueTitle
      await this.getDocument(titleName)
      // const titles = await getDocumentTitle(titleName)
      // this.titleList = titles.data.rows
      // this.documentList = this.titleList
    },
    //输入框按下回车
    enterBtn(){
      this.onSearch()
    },
    //重置按钮
    resetBtn(){
      this.valueTitle = ''
      //恢复初始状态，默认不显示隐藏的
      this.showBtn = 0
      this.getDocument()
    },
    //点击标题查看详情
    // list_content(row){
    //   this.row_content = true
    //   this.spinning = true
    //   //1.点击标题获取整行的信息 row
    //   //2.将信息赋值给ticketInfoform
    //   row.show_date = moment(row.show_date).format("YYYY-MM-DD")
    //   this.ticketInfoform = row
    //   //3.显示弹出框
    //   this.row_content = true
    //   this.spinning = false
    // },
    // //关闭详情对话框
    // content_close(){
    //   this.row_content = false
    // }
  },


}
</script>

<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}
.document{
  margin: -15px -15px;
  .document-content{
    //出现滑动效果
    overflow: auto;
    width: 100%;
    border: 1px solid #f6f5f5;
    margin-top: 10px;
  }
  .document-btn{
    .aBtn{
      width: 70px;
      height: 35px;
      color:#ffffff;
      background-color: rgb(31,147,229);
    }
  }
}
.addVisible{
  //  上传图片的样式
  .add-image{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addImage.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    cursor: pointer;
    .imageAdd{
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }
  .imageCard{
    width: 278px;
    margin-left: 28%;
  }
  .add-file {
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addFile.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    .fileAdd {
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }
  .fileCard{
    width: 278px;
    margin-left: 28%;
  }
}
//修改界面
.modFrom{
  .mod-image{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addImage.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    cursor: pointer;
    .imageMod{
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }
  .modImage{
    width: 278px;
    margin-left: 28%;
  }
  .mod-file{
    margin-top: 8px;
    width: 80px;
    height: 25px;
    background-image: url("../../assets/addFile.png") ;
    background-repeat: no-repeat ;
    background-size: 80px 25px;
    float: left;
    .fileMod{
      width: 80px;
      height: 25px;
      opacity: 0;
    }
  }
  .modFile{
    width: 278px;
    margin-left: 28%;
  }
}
.seachTitle{
  width: 100%;
  height: 135px;
  border-bottom: 15px solid #f5f5f5;
  padding: 15px 15px;
}
.tab_btn{
  display: flex;
  background-color: #f5f5f5;
}
.issue{
  width: 80px;
  height: 50px;
  line-height:50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  cursor: pointer;
}
.issue:hover{
  color: #1890ff;
}
.issue:active{
  color: #1890ff;
}
.stick{
  width: 80px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.stick:hover{
  color: #1890ff;
}
.stick:active{
  color: #1890ff;
}
.unIssue{
  width: 80px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f5f5f5;
  background-color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.unIssue:hover{
  color: #1890ff;
}
.unIssue:active{
  color: #1890ff;
}
</style>