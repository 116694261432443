import {get, post, put, del, getExel} from './base'

/**
 * 获取直播列表
 * @param page_no
 * @param page_size
 * @param title
 * @returns {Promise<* | void>}
 */
export function getLiveList(pageNo,pageSize,title,isShow,sortTime,domainCode){
    return get("/api/admin/liveMettingSpecials",{pageNo,pageSize,title,isShow,sortTime,domainCode})
}

/**
 * 修改直播
 * @param id
 * @param data
 * @returns {Promise<* | void>}
 */
export function putLiveList(id,data){
    return put("/api/admin/liveMettingSpecial/"+id,data)
}
/**
 * 添加直播
 * @param data
 * @returns {Promise<* | void>}
 */
export function postLiveList(data){
    return post("/api/admin/liveMettingSpecial",data)
}
//直播会议上移下移
export function LiveList_upOrDown(id,action){
    return put("/api/admin/liveMettingSpecialMove/"+id+'/'+action)
}
/**
 * 获取直播标题和
 * @param id
 * @returns {Promise<* | void>}
 */
export function getListContent(id){
    return get("/api/admin/liveMettingSpecial/"+id)
}

/**
 * 获取设置直播列表
 * @param page_no
 * @param page_size
 * @param title
 * @param live_date
 * @returns {Promise<* | void>}
 */
// export function getSetLiveList(page_no,page_size,title,start_time,live_metting_id){
//     return get("/api/admin/liveMeet",{page_no,page_size,title,start_time,live_metting_id})
// }
export function getSetLiveList(data){
    return get("/api/admin/liveMeet",data)
}

export function putSetLiveList(id,data){
    return put("/api/admin/liveMeet/"+id,data)
}

/**
 * 获取单个直播间
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function getOneLiveList(id){
    return get("/api/admin/liveMeet/"+id)
}

/**
 * 原始观看记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_Original_viewing(data){
    return get('/api/admin/liveWatchRecords',data)
}
/**
 * 原始观看记录---手动上报
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_Original_viewing_operation(data){
    return get('/api/admin/liveReportedRecords',data)
}
/**
 * 人员观看总时长
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_People_watch(data){
    return get('/api/admin/liveWatchRecordTotalTimes',data)
}
/**
 * 人员观看总时长---手动上报
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_People_watch_operation(data){
    return get('/api/admin/liveReportedRecordTotalTimes',data)
}
/**
 * 姓名医院登记记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_name_hos(data){
    return get('/api/admin/liveNameHospitalRecord',data)
}

/**
 * 聊天记录
 * @param watch_type
 * @returns {Promise<* | void>}
 */
export function get_chatting_records(data){
    return get('/api/admin/liveChatRecord',data)
}
export function delList(liveChatIds){
    return del('/api/admin/liveChatRecord',{liveChatIds})
}

/**
 * 删除
 * @param liveMeetingId
 * @returns {Promise<* | void>}
 */
export function delSetLiveList(liveMeetingId){
    return del('/api/admin/liveMeet/'+ liveMeetingId)
}

/**
 * 修改聊天室
 * @param id
 * @returns {Promise<* | void>}
 */
export function putVodHide(id,data){
    return put('/api/admin/liveChatRecord/'+ id,data)
}

//-----------------------------------------直播问答管理-------------------------------------------
/**
 * 问题模板列表
 * @returns {Promise<unknown | void>}
 */
export function getAnswer(title,is_open,page_no,page_size){
    return get('/api/admin/liveQuestionTemplates',{title,is_open,page_no,page_size})
}

/**
 * 新增答题模板
 * @param data
 * @returns {Promise<* | void>}
 */
export function postAnsList(data){
    return post('/api/admin/liveQuestionTemplate',data)
}

/**
 * 修改答题模板
 * @param data
 * @returns {Promise<unknown | void>}
 */
export function putAnsList(id,data){
    return put('/api/admin/liveQuestionTemplate/' + id,data)
}

export function delAnsList(id){
    return del('/api/admin/liveQuestionTemplate/' + id)
}

/**
 * 答题记录列表
 * @param templateId
 * @returns {Promise<unknown | void>}
 */
export function getAnswerRecord(questionId,answer,liveStreamTitle){
    return get('/api/admin/liveQuestionLogs',{questionId,answer,liveStreamTitle})
}
//-------------------------直播抽奖活动---中奖名单-------------------------------

/**
 * 直播抽奖活动列表
 * @returns {Promise<unknown | void>}
 */
export function getActivityList(page_no,page_size,title,isFinish){
    return get("/api/admin/activities",{page_no,page_size,title,isFinish})
}

/**
 * 获取中奖列表
 * @param activity_id
 * @param page_no
 * @param page_size
 * @returns {Promise<* | void>}
 */
export function getRaffle(activity_id,page_no,page_size,release_status,tel){
    return get("/api/admin/raffleWinnings", {activity_id,page_no,page_size,release_status,tel})
}


export function putRaffle(raffleWinnings,data){
    return put("/api/admin/raffleWinning/" +raffleWinnings, data)
}

//----------------------权限管理---通讯录管理-----------------------
/**
 * 获取左侧列表
 * @returns {Promise<* | void>}
 */
export function getCommunictionLeft(){
    return get("//api/admin/departments")
}

/**
 * 获取列表
 * @param page_no
 * @param page_size
 * @param type
 * @param userName
 * @param departmentById
 * @returns {Promise<* | void>}
 */
export function getCommunictionList(page_no,page_size,type,userName,departmentById){
    return get("/api/admin/adminUsers",{page_no,page_size,type,userName,departmentById})
}

/**
 * 同步
 * @returns {Promise<* | void>}
 */
export function synchronization(){
    return get("/api/admin/auth/synchronization")
}

//下载
export function Download_answers_exal(data){
    return getExel("/api/admin/export/liveQuestionLogs",data)
}

//下载原始观看记录
export function Download_OriginalViewing_exal(data){
    return getExel("/api/admin/liveWatchRecords/export-excel",data)
}
//下载人员观看总时长
export function Download_PeopleWatch_exal(data){
    return getExel("/api/admin/liveWatchRecordTotalTimes/export-excel",data)
}
//下载姓名医院登录记录
export function Download_NameHos_exal(data){
    return getExel("/api/admin/liveNameHospitalRecord/export-excel",data)
}
//下载聊天室记录
export function Download_ChattingRecords_exal(data){
    return getExel("/api/admin/liveChatRecord/export-excel",data)
}