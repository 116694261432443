import Vue from 'vue'
import VueRouter from 'vue-router'
import ManageRoot from '../views/ManageRoot'
import live  from '../views/content/Live'
import Document  from '../views/content/Document'
import Magazine  from '../views/content/Magazine'
import Tool  from '../views/content/Tool'
import Activity  from '../views/content/Activity'
import Recruitment  from '../views/content/Recruitment'
import Login from '../views/Login'
import Test from '../views/Test'
import Home_page from '../views/content/HomePage'
import MedicalStaff from "../views/content/auth/MedicalStaff";
import MedicalStudent from "../views/content/auth/MedicalStudent";
import AuthOther from "../views/content/auth/AuthOther";
import Iottery from "../views/content/Iottery";
import Winners from "../views/content/Winners";
import ImageManagement from "@/views/content/ImageManagement";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'ManageRoot',
    component: ManageRoot,
    children:[
      {
        path: '/',
        name: 'Home_page',
        component: Home_page
      },
      {
        path:'live',
        name:'live',
        component:live
      },
      //直播管理
      {
        path:'liveMeet',
        name:'liveMeet',
        component:()=>import('@/views/content/LiveMeet'),
      },
      //直播管理，点击数据跳转
      {
        path:'liveInformation',
        name:'liveInformation',
        component:()=>import('@/views/content/liveInformation'),
      },
      //直播管理，直播问答管理
      {
        path:'liveAnswers',
        name:'liveAnswers',
        component:()=>import('@/views/content/liveAnswers'),
      },
      //直播管理，查看答题记录
      {
        path:'liveAnswerRecord',
        name:'liveAnswerRecord',
        component:()=>import('@/views/content/liveAnswerRecord'),
      },
      //设置直播间管理
      {
        path:'setLiveMeet',
        name:'setLiveMeet',
        component:()=>import('@/views/content/setLiveMeet')
      },
      {
        path:'document',
        name:'document',
        component:Document
      },
      {
        path:'magazine',
        name:'magazine',
        component:Magazine
      },
      {
        path:'tool',
        name:'tool',
        component:Tool
      },
      {
        path:'activity',
        name:'activity',
        component:Activity
      },
      {
        path:'iottery',
        name:'Iottery',
        component:Iottery
      },
      {
        path:'winners',
        name:'Winners',
        component:Winners
      },
      {
        path:'recruitment',
        name:'recruitment',
        component:Recruitment
      },
      {
        //医护人员管理
        path: '/medicalStaff',
        name: 'MedicalStaff',
        component: MedicalStaff
      },
      {
        //医学生管理
        path: '/medicalStudent',
        name: 'MedicalStudent',
        component: MedicalStudent
      },
      {
        //其他用户管理
        path: '/authOther',
        name: 'AuthOther',
        component: AuthOther
      },
      {
        // 图片素材管理
        path:'imageManagement',
        name:'imageManagement',
        component:ImageManagement
      },
      {
        // 病例首页
        path:'caseHome',
        name:'caseHome',
        component:()=>import ('@/views/content/case/CaseHome')
      },
      {
        // 病例管理
        path:'case',
        name:'Case',
        component:()=>import ('@/views/content/case/Case')
      },
      {
        // 目标医院审核
        path:'targetHospitalAudit',
        name:'targetHospitalAudit',
        component:()=>import ('@/views/content/case/targetHospitalAudit')
      },
      {
        // 病历图片打码管理
        path:'casePicturesCode',
        name:'CasePicturesCode',
        component:()=>import ('@/views/content/case/CasePicturesCode')
      },
      {
        // 图片打码
        path:'caseCode',
        name:'CaseCode',
        component:()=>import ('@/views/content/case/CaseCode')
      },
      {
        // 病例审批管理
        path:'caseAudit',
        name:'CaseAudit',
        component:()=>import ('@/views/content/case/CaseAudit')
      },
      {
        // 病例审核管理
        path:'caseReview',
        name:'caseReview',
        component:()=>import ('@/views/content/case/caseReview')
      },
      {
        // 病例审核管理
        path:'caseReviewTwo',
        name:'caseReviewTwo',
        component:()=>import ('@/views/content/case/caseReviewTwo')
      },
      {
        // 病例审核管理--主动脉
        path:'caseAorta',
        name:'caseAorta',
        component:()=>import ('@/views/content/case/caseAorta')
      },
      {
        // 病例审核管理--脓毒症
        path:'casePyohemia',
        name:'CasePyohemia',
        component:()=>import ('@/views/content/case/CasePyohemia')
      },
      {
        // 病例审核管理--心率失常
        path:'caseArrhythmia',
        name:'CaseArrhythmia',
        component:()=>import ('@/views/content/case/CaseArrhythmia')
      },
      {
        // 病例打印管理
        path:'casePrint',
        name:'CasePrint',
        component:()=>import ('@/views/content/case/CasePrint')
      },
      {
        // 病例结算管理
        path:'caseAccount',
        name:'CaseAccount',
        component:()=>import ('@/views/content/case/CaseAccount')
      },
      {
        // 医院管理
        path:'hospital',
        name:'Hospital',
        component:()=>import ('@/views/content/auth/Hospital')
      },
      {
        // 学校管理
        path:'school',
        name:'School',
        component:()=>import ('@/views/content/auth/School')
      },
      {
        // 质控平台账号管理
        path:'zkAuth',
        name:'zkAuth',
        component:()=>import ('@/views/content/auth/ZKAuth')
      },
      {
        // 会员导入管理
        path:'memberImport',
        name:'MemberImport',
        component:()=>import ('@/views/content/auth/MemberImport')
      },
      {
        // 会员认证
        path:'memberAudit',
        name:'memberAudit',
        component:()=>import ('@/views/content/auth/memberAudit')
      },
      {
        // 病例项目
        path:'caseProject',
        name:'CaseProject',
        component:()=>import ('@/views/content/case/CaseProject')
      },
      {
        // 病例项目进展查询
        path:'caseProgress',
        name:'caseProgress',
        component:()=>import ('@/views/content/case/caseProgress')
      },
      {
        //通讯录管理
        path:'communicationManagement',
        name:'communicationManagement',
        component:()=>import ('@/views/content/systemSetting/CommunicationManagement')
      },
      {
        //菜单管理
        path:'menuManagement',
        name:'menuManagement',
        component:()=>import ('@/views/content/systemSetting/menuManagement')
      },
      {
        //角色管理
        path:'roleManagement',
        name:'roleManagement',
        component:()=>import ('@/views/content/systemSetting/roleManagement')
      },
      {
        //客户账户管理
        path:'customerAccount',
        name:'customerAccount',
        component:()=>import ('@/views/content/systemSetting/customerAccount')
      },
      {
        //操作日志
        path:'operationLog',
        name:'operationLog',
        component:()=>import ('@/views/content/auth/operationLog')
      },
      {
        //后台操作日志
        path:'mainOperationLog',
        name:'mainOperationLog',
        component:()=>import ('@/views/content/systemSetting/mainOperationLog')
      },
      //医学会议管理--会议管理
      {
        path:'meetingManagement',
        name:'MeetingManagement',
        component:()=>import('@/views/content/medicalConference/MeetingManagement')
      },
      {
        path: 'featureManagement',
        name: 'featureManagement',
        component: () => import('@/views/content/medicalConference/featureManagement')
      },
      {
        path: 'featureDHZB',
        name: 'FeatureDHZB',
        component: () => import('@/views/content/medicalConference/FeatureDHZB')
      },
      {
        path: 'featureDuration',
        name: 'FeatureDuration',
        component: () => import('@/views/content/medicalConference/FeatureDuration')
      },
      {
        //首页模板地址管理
        path:'templateAddress',
        name:'templateAddress',
        component:()=>import ('@/views/content/medicalConference/templateAddress')
      },
      {
        //首页功能类型管理
        path:'functionTypes',
        name:'functionTypes',
        component:()=>import ('@/views/content/medicalConference/functionTypes')
      },
      {
        //首页模板管理
        path:'homepageTemplate',
        name:'homepageTemplate',
        component:()=>import ('@/views/content/medicalConference/homepageTemplate')
      },
      {
        //首页功能管理
        path:'commonFunctions',
        name:'commonFunctions',
        component:()=>import ('@/views/content/medicalConference/commonFunctions')
      },
      {
        //栏目管理
        path:'programManagement',
        name:'programManagement',
        component:()=>import ('@/views/content/dataDictionary/programManagement')
      },
      {
        //职称管理
        path:'titleManagement',
        name:'titleManagement',
        component:()=>import ('@/views/content/dataDictionary/titleManagement')
      },
      {
        //注册科室管理
        path:'departmentsPlatform',
        name:'departmentsPlatform',
        component:()=>import ('@/views/content/dataDictionary/departmentsPlatform')
      },
      {
        //医院待清洗
        path:'hospitalCleaning',
        name:'hospitalCleaning',
        component:()=>import ('@/views/content/dataCleaned/hospitalCleaning')
      },
      {
        //科室待清洗
        path:'departmentCleaning',
        name:'departmentCleaning',
        component:()=>import ('@/views/content/dataCleaned/departmentCleaning')
      },
      {
        //职称待清洗
        path:'titleCleaning',
        name:'titleCleaning',
        component:()=>import ('@/views/content/dataCleaned/titleCleaning')
      },
      {
        //题库管理
        path:'questionBank',
        name:'questionBank',
        component:()=>import ('@/views/content/titleExam/questionBank')
      },
      {
        //帮助中心内容统计
        path:'helpCenterStatistics',
        name:'HelpCenterStatistics',
        component:()=>import ('@/views/content/titleExam/HelpCenterStatistics')
      },
      {
        //帮助中心内容管理
        path:'helpCenter',
        name:'HelpCenter',
        component:()=>import ('@/views/content/titleExam/HelpCenter')
      },
      {
        //职称题目分类
        path:'examType',
        name:'examType',
        component:()=>import ('@/views/content/titleExam/examType')
      },
      {
        //同步练习分类
        path:'exercisesType',
        name:'exercisesType',
        component:()=>import ('@/views/content/titleExam/exercisesType')
      },
      {
        //同步练习管理
        path:'synchronizeExercises',
        name:'synchronizeExercises',
        component:()=>import ('@/views/content/titleExam/synchronizeExercises')
      },
      {
        //模拟考试管理
        path:'practiceTest',
        name:'practiceTest',
        component:()=>import ('@/views/content/titleExam/practiceTest')
      },{
        //点播视频管理
        path:'videoOnDemand',
        name:'videoOnDemand',
        component:()=>import ('@/views/content/videoDemand/VideoOnDemand')
      },
      {
        //视频库管理
        path:'videoLibrary',
        name:'videoLibrary',
        component:()=>import ('@/views/content/videoDemand/VideoLibrary')
      },{
        //视频片头片尾管理
        path:'openingCredits',
        name:'OpeningCredits',
        component:()=>import ('@/views/content/videoDemand/OpeningCredits')
      },
      {
        //首页功能管理---Banner广告管理
        path:'homeBannerManagement',
        name:'homeBannerManagement',
        component:()=>import ('@/views/content/homePageFeatures/HomeBannerManagement')
      },
      {
        //首页功能管理---栏目首页内容管理
        path:'columnHomepage',
        name:'columnHomepage',
        component:()=>import ('@/views/content/homePageFeatures/columnHomepage')
      },
      {
        //社区功能管理---社区首页内容管理
        path:'communityContent',
        name:'communityContent',
        component:()=>import ('@/views/content/homePageFeatures/communityContent')
      },
      // {
      //   //平台内容管理---内容（外链）
      //   path:'contentUrlManagement',
      //   name:'contentUrlManagement',
      //   component:()=>import ('@/views/content/platformContent/contentUrlManagement')
      // },
      // {
      //   //平台内容管理---内容（pdf）
      //   path:'contentPDFManagement',
      //   name:'contentPDFManagement',
      //   component:()=>import ('@/views/content/platformContent/contentPDFManagement')
      // },
      // {
      //   //平台内容管理---内容（富文本）
      //   path:'contentTextManagement',
      //   name:'contentTextManagement',
      //   component:()=>import ('@/views/content/platformContent/contentTextManagement')
      // },
      {
        //平台内容管理---招聘信息抓取
        path:'recruitInfoCapture',
        name:'recruitInfoCapture',
        component:()=>import ('@/views/content/platformContent/recruitInfoCapture')
      },
      {
        //平台内容管理---文章管理
        path:'activeManager',
        name:'activeManager',
        component:()=>import ('@/views/content/platformContent/activeManager')
      },
      {
        //首页功能内容管理---社区首页功能管理
        path:'communityEnableFunction',
        name:'communityEnableFunction',
        component:()=>import ('@/views/content/communityFunction/CommunityEnableFunction')
      },
      {
        //首页功能管理---App首页功能管理
        path:'enableFunction',
        name:'enableFunction',
        component:()=>import ('@/views/content/homePageFeatures/EnableFunction')
      },
      {
        //系统管理---个人中心功能管理
        path:'personalCenterManagement',
        name:'personalCenterManagement',
        component:()=>import ('@/views/content/systemSetting/PersonalCenterManagement')
      },
      {
        //系统管理---热搜词管理
        path:'hotSearchWords',
        name:'hotSearchWords',
        component:()=>import ('@/views/content/systemSetting/HotSearchWords')
      },
      {
        //系统管理---H5链接设置
        path:'h5LinkSet',
        name:'h5LinkSet',
        component:()=>import ('@/views/content/systemSetting/H5LinkSet')
      },
      {
        //系统管理---用户投诉管理
        path:'userComplaints',
        name:'userComplaints',
        component:()=>import ('@/views/content/systemSetting/UserComplaints')
      },
      {
        //系统管理---意见反馈管理
        path:'feedback',
        name:'feedback',
        component:()=>import ('@/views/content/systemSetting/Feedback')
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
]

const router = new VueRouter({
  routes
})

export default router


router.beforeEach((to,from,next)=>{
  //如果是登录页面路径，就直接next()
  if (window.localStorage.getItem("user_info")){
    //获取本地存储信息，并转换为Json格式
    const userData = JSON.parse(window.localStorage.getItem("user_info"))
    //判断有没有id
    if(userData.id !== '' && userData.id !== undefined && userData.id !== null){
      //返回上一层路由
      next(-1);
    }
  }else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
  }
})
