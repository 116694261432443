<template>
  <div class="Recruitment">
  <a-spin :spinning="spinning">
    <div style="display: flex;justify-content: space-between">
      <a-button type="primary" @click="()=>{this.addVisible = true}" style="margin-bottom: 10px" >添加</a-button>
      <a-input-search
          style="width: 300px"
          placeholder="输入标题搜索"
          enter-button @search="search_getRecruitmentList" />
    </div>

<!--    tab 栏目  -->
    <a-tabs type="card" @change="tab_change">
      <a-tab-pane key="1" tab="已发布">
      </a-tab-pane>
      <a-tab-pane key="2" tab="已置顶">
      </a-tab-pane>
      <a-tab-pane key="3" tab="未发布">
      </a-tab-pane>
    </a-tabs>
    <a-table
        rowKey="r_id" :columns="tableColumns"  :data-source="recruitmentList"
        @change="listChangePageNo"
        :pagination=pagination
    >
    <!--   标题   -->
      <span slot="title_link" slot-scope="text,record">
        <a @click="list_link(record)">{{record.title}}</a>
      </span>
      <a-space slot="operation" slot-scope="text,record">
        <a-button :type="record.is_show === 1?'danger':'primary'" @click="change_is_show(record.r_id,record.is_show === 1?0:1)">{{record.is_show === 1?'隐藏':'显示'}}</a-button>
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="getAlterRecruitment(record.r_id)">基本信息修改</a-menu-item>
            <a-menu-item key="2" @click="open_updateContent(record.r_id)"> 内容修改 </a-menu-item>
          </a-menu>
          <a-button type="primary"> 修改 <a-icon type="down" /> </a-button>
        </a-dropdown>
      </a-space>
    </a-table>

<!--    添加对话框     -->
    <a-modal class="addVisible" :keyboard="false" :maskClosable="false" v-model="addVisible" title="添加招聘" @ok="getAddRecruitment">
      <a-form-model
          ref="addFrom"
          :rules="FromRules"
          :model="addFrom" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
        <!--        封面图-->
        <a-form-model-item label="封面图" prop="image">
          <div class="updateFile">
            <input type="file" ref="addUpdateImage" class="container"
                   @change="ImageUpdate('add')"
            accept="image/*">
          </div>
        </a-form-model-item>
        <a-card class="image_container" style="width: 300px">
          预览图:
          <img v-if="addFrom.image" :src="addFrom.image" :alt="addFrom.image">
        </a-card>
<!--      标题  -->
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="addFrom.title" />
        </a-form-model-item>
<!--        描述-->
        <a-form-model-item label="描述" prop="r_desc">
          <a-input v-model="addFrom.r_desc" />
        </a-form-model-item>
<!--        显示时间-->
        <a-form-model-item label="显示时间" prop="show_time">
          <a-date-picker  v-model="addFrom.show_time"/>
        </a-form-model-item>
<!--        备注-->
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="addFrom.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--    修改对话框-->
    <a-modal class="addVisible" :keyboard="false" :maskClosable="false" v-model="alterVisible" title="修改招聘" @ok="submitAlterRecruitment">
      <a-form-model
          ref="alterFrom"
          :rules="FromRules"
          :model="alterFrom" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
        <!--        封面图-->
        <a-form-model-item label="封面图" prop="image">
          <div class="updateFile">
            <input type="file" ref="addUpdateImage" class="container"
                   @change="ImageUpdate('alter')"
                   accept="image/*">
          </div>
        </a-form-model-item>
        <a-card class="image_container" style="width: 300px">
          预览图:
          <img v-if="alterFrom.image" :src="alterFrom.image" :alt="alterFrom.image">
        </a-card>
        <!--      标题  -->
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="alterFrom.title"/>
        </a-form-model-item>
        <!--        描述-->
        <a-form-model-item label="描述" prop="r_desc">
          <a-input v-model="alterFrom.r_desc" />
        </a-form-model-item>
        <!--        显示时间-->
        <a-form-model-item label="显示时间" prop="show_time">
          <a-date-picker  v-model="alterFrom.show_time"/>
        </a-form-model-item>
        <!--        备注-->
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model="alterFrom.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

<!--    修改内容对话框-->
    <a-modal width="85%"
             :keyboard="false" :maskClosable="false"
             :title="'内容修改ID:'+alter_content_r_id" v-model="contentVisible"
             :confirmLoading="spinning"
             @ok="updateContent">
      <a-spin :spinning="spinning">
        <!--      富文本编辑框-->
        <div  ref="editor"></div>
      </a-spin>
    </a-modal>

<!--    点击标题，显示对话框-->
    <a-modal
        :visible="list_content"
        @cancel="content_close"
    >
      <a-descriptions title="详情">
        <a-descriptions-item label="标题" :span="3">
          {{alterFrom.title}}
        </a-descriptions-item>
        <a-descriptions-item label="描述" :span="3">
          {{alterFrom.r_desc}}
        </a-descriptions-item>
        <a-descriptions-item label="显示日期" :span="3">
          {{alterFrom.show_time}}
        </a-descriptions-item>
        <a-descriptions-item label="上传图片" :span="3">
          <img style="width: 200px;height: auto" :src="alterFrom.image"/>
        </a-descriptions-item>
        <a-descriptions-item label="备注" :span="3">
          {{alterFrom.remark}}
        </a-descriptions-item>
      </a-descriptions>
      <!--    去除a-model自带的取消和确认-->
      <div slot="footer"></div>
    </a-modal>

  </a-spin>
  </div>
</template>

<script>
import {
  addRecruitment,
  recruitmentList,
  alterRecruitment,
  recruitmentListById,
  alterRecruitmentContent
} from "@/service/recruitment";
import {update} from "@/utils/update";
import moment from "moment";
import E from 'wangeditor'

export default {
  name: "Recruitment",
  data(){
    return{
      list_content:false,
      //加载框
      spinning:true,
      recruitmentList:[],
      tableColumns : [
        {
          dataIndex: 'r_id',
          title:'ID',
          width: '5%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          dataIndex: 'title',
          title:'标题',
          width: '20%',
          scopedSlots: { customRender: 'title_link' },
        },
        {
          dataIndex: 'r_desc',
          title:'描述',
          width: '25%',
        },
        {
          dataIndex: 'show_time',
          title:'显示时间'
        },
        // {
        //   dataIndex: 'image',
        //   title:'封面图',
        //   scopedSlots:{customRender: 'image'}
        // },
        {
          dataIndex: 'remark',
          title:'备注',
          width: '15%',
        },
        // {
        //   dataIndex: 'create_time',
        //   title:'创建时间'
        // },
        {
          dataIndex: 'operation',
          title:'操作',
          width: '20%',
          scopedSlots:{customRender: 'operation'}
        }
      ],
      //表单验证规则
      FromRules:{
        image: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        title: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
        r_desc: [
          {
            required: true,
            trigger: 'blur'
          },
        ],
        show_time: [
          {
            required: true,
            trigger: 'blur'
          },
        ],
        remark: [
          {
            required: false,
            trigger: 'blur'
          },
        ],
      },
      //添加表单
      addVisible : false,
      addFrom:{
        // image:'https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/aa84bd90-1ed8-478e-b097-72cec56ece92.png'
        image:''
      },
      //修改表单
      alterVisible:false,
      alterFrom:{},
      //内容相关
      contentVisible:false,
      //内容与编辑器
      //富文本显示内容编辑
      // 默认情况下，显示所有菜单
      editor_menus:[
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        'lineHeight',
        'foreColor',
        'backColor',
        'link',
        'list',
        'todo',
        'justify',
        'quote',
        'emoticon',
        'image',
        'table',
        'splitLine',
        'undo',
        'redo',
      ],
      alter_content_r_id:0,
      content:'',
      editor:null,
      //搜索输入框的内容
      search_value:"",
      //分页配置
      pagination:{
        //配置分页
        pageSize:10,
        //总数
        total:0,
        //默认的当前页数
        current:1
      },
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"招聘管理")
  },
  async mounted() {
    await this.getRecruitmentList()
  },
  methods: {
    //获取招聘列表
    async getRecruitmentList(data) {
      //设置默认显示已发布
      if (data===null||data===undefined){
        data = {
          is_show:1,
        }
      }
      //加载
      this.spinning = true
      const response = await recruitmentList(data);
      if (response.code === 0) {
        this.recruitmentList = response.data.rows
        this.pagination.total = response.data.count
      } else {
        this.recruitmentList = null
        this.$message.error("列表获取错误:"+response.message)
      }
      //放开加载
      this.spinning = false
    },

    //搜索功能
    async search_getRecruitmentList(value){
      this.search_value = value
      //加载
      this.spinning = true
      const data = {
        title:value
      }
      await this.getRecruitmentList(data);
      //放开加载
      this.spinning = false
    },


    //修改显示隐藏
    async change_is_show(id, is_show) {
      //加载
      this.spinning = true
      const data = {
        r_id: id,
        is_show
      }
      const response = await alterRecruitment(data);
      if (response.code === 0) {
        //刷新列表
        await this.getRecruitmentList()
      } else {
        this.$message.error("修改状态失败，请稍后尝试。"+response.message)
      }
      //放开加载
      this.spinning = false
    },
    //提交添加内容
    getAddRecruitment() {
      //放开加载
      this.spinning = false
      //非空判断
      this.$refs.addFrom.validate(async valid => {
        if (valid) {
          const response = await addRecruitment(this.addFrom);
          if (response.code === 0) {
            this.$message.success("添加成功！")
            //刷新列表
            await this.getRecruitmentList();
            //清空
            this.$refs.addFrom.resetFields();
            //关闭弹框
            this.addVisible = false
          } else {
            this.$message.error("添加失败！"+response.message)
          }
        } else {
          this.$message.error("请完整填写后再尝试。")
        }
      })
      //放开加载
      this.spinning = false
    },
    //上传图片
    async ImageUpdate(type) {
      let file = this.$refs.addUpdateImage.files[0];
      if (file !== undefined) {
        const response = await update(file);
        if (response.code === 0) {
          //  上传成功
          //判断赋值到添加还是修改(add,alter)
          if (type === "add") {
            this.addFrom.image = response.data.url
            this.$message.success('上传成功！')
          } else if (type === "alter") {
            this.alterFrom.image = response.data.url
            this.$message.success('上传成功！')
          } else {
            this.$message.error("赋值出错，请联系管理员~")
          }
        } else {
          //  上传失败
          this.$message.error("上传失败，请重试。"+response.message)
        }
      } else {
        console.log(file)
      }
    },

    //点击修改按钮
    async getAlterRecruitment(r_id) {
      //1.通过id获取详情
      const response = await recruitmentListById(r_id);
      if (response.code === 0) {
        //  赋值
        let alterFrom = response.data[0]
        alterFrom.show_time = moment(alterFrom.show_time, 'YYYY-MM-DD')
        this.alterFrom = alterFrom
        //  打开修改框
        this.alterVisible = true
      } else {
        //  弹出错误
        this.$message.error("获取详情失败，请稍后再试。"+response.message)
      }
    },
    //  确认修改
    async submitAlterRecruitment() {
      this.spinning = true
      this.$refs.alterFrom.validate(async valid => {
        if (valid) {
          //赋值
          let alterFrom = this.alterFrom;
          alterFrom.show_time = moment(alterFrom.show_time).format("YYYY-MM-DD")
          const response = await alterRecruitment(alterFrom);
          if (response.code === 0){
            await this.getRecruitmentList()
            this.$message.success("成功")
            this.alterVisible = false
            alterFrom.show_time = moment(alterFrom.show_time,"YYYY-MM-DD")
          }else {
            this.$message.error("添加失败"+response.message)
          }
        }else {
          this.$message.error("请填写完整后再修改！")
        }
      })
      this.spinning = false
    },

    //配置富文本编辑器
    async editor_config(){
      // 初始化富文本编辑器
      const editor =  new E(this.$refs.editor)
      this.editor = editor
      // 设置编辑区域高度为 500px
      editor.config.height = 500
      //配置菜单栏
      editor.config.menus = this.editor_menus
      //限制一次最多能传几张图片
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
      //配置上传图片接口
      editor.config.customUploadImg = async function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const response = await update(resultFiles[0]);
        if (response.code === 0) {
          // 上传图片，返回结果，将图片插入到编辑器中
          //url: "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/cdeb804c-b6a2-4610-b1bf-a517288ff3e1.png"
          insertImgFn(response.data.url)
        } else {
          window.alert("图片上传失败",response.message)
        }
      }
      //创建编辑器
      await editor.create()
    },

//  获取对应内容
    async getContent(id){
      const response = await recruitmentListById(id);
      if (response.code === 0){
        this.content = response.data.content
      }
    },
  //  打开内容修改的弹窗
    async open_updateContent(id){
      this.spinning = true
      this.alter_content_r_id = id
      //获取内容
      const response = await recruitmentListById(id);
      if (response.code === 0){
        //赋值
        const  content = response.data[0].content
        //打开弹窗
        this.contentVisible = true
          //等待dom元素更新
          this.$nextTick(async () => {
            //只初始化一次富文本编辑器
            if (!this.editor){
              //初始化富文本编辑器
              await this.editor_config(content)
            }
            //创建编辑器后设置内容
            this.editor.txt.html(content)
          })
      }else {
        this.$message.error("获取内容错误。"+response.message)
      }
      this.spinning = false
    },
  //  提交内容的修改
    async updateContent() {
      this.spinning = true
      const data = {
        r_id: this.alter_content_r_id,
        content: this.editor.txt.html()
      }
      const response = await alterRecruitmentContent(data);
      if (response.code === 0 ){
        this.$message.success("修改成功！")
        this.contentVisible = false
      }else {
        this.$message.error("内容修改失败，请稍后再试。"+response.message,response.erro)
      }
      this.spinning = false
    },
    //点击标题显示详情对话框
    list_link(row){
    //  1.获取信息
    //  2.赋值
      row.show_date = moment(row.show_date).format("YYYY-MM-DD")
      this.alterFrom = row
    //  3.显示对话框
      this.list_content = true
    },
    //关闭标题详情对话框
    content_close(){
      this.list_content = false
    },
    /**
     * 改变栏目
     */
    async tab_change(key){
      this.spinning = true
      let data = {
        is_show:1,
        title:this.search_value
      }
      switch (key) {
        case "1":{
          data.is_show = 1
          break;
        }
        case "3":{
          data.is_show = 0
          break;
        }
        default:{
          data.is_show=null
          break;
        }
      }
      await this.getRecruitmentList(data)
      this.spinning = false
    },
    /**
     * 改变页码
     */
    listChangePageNo(pagination){
      const data = {
        "page_no":pagination.current
      }
      this.getRecruitmentList(data)
      this.pagination = pagination
    }
  }
}
</script>

<style scoped lang="scss">

.addVisible{
  .updateFile{
    margin-top: 7px;
    height: 25px;
    width: 80px;
    background-image: url("../../assets/addImage.png");
    background-size: 80px 25px;
    cursor: pointer;
    .container{
      height: 25px;
      width: 80px;
      opacity: 0;
      cursor: pointer;
    }
  }
  .image_container{
    margin: -20px 0 10px 16%;
    img{
      max-width: 80%;
    }
  }
}
</style>