/**
 * name:fmx
 * data:2021年12月16日
 */
import{get,put,post,del}from"./base";
//文献指南
export function getDocument(is_show,title,page_no,page_size,is_top){
    return get('/api/admin/document',{is_show,title,page_no,page_size,is_top})
}
// export function getDocumentTitle(title){
//     return get('/api/admin/document',{"title":title})
// }
//修改文献列表，获取详情
export function getDocumentContent(id){
    return get('/api/admin/document/'+ id)
}
//删除文献列表
export function delList(ids){
    return del('/api/admin/document',{ids:ids})
}
//{"id":id,"is_show":'is_show'}
export function putDocument( data){
    return put('/api/admin/document',data)
}
export function putDocumentContent(data){
    return put('/api/admin/document',data)
}
//{"title":title,"describe":describe,"show_date":show_date,"image":image,"url":url,"remake":remake}
export function postDocument( data){
    return post('/api/admin/document',data)
}
//添加图片
export function postDocumentImg(File){
    return post('/api/admin/common/update',File,{headers:{'Content-Type':'multipart/form-data'}})
}
//添加文件
export function postDocumentFile(File){
    return post('/api/admin/common/updateFile',File, {headers: {'Content-Type':'multipart/form-data'}})
}
//登陆界面，成功传数据 json   {headers: {'Content-Type':'application/json'}}
export function postAdminData(name,data){
    return post('/api/admin/auth/login_info/' + name,data,{headers: {'Content-Type':'application/json'}})
}
