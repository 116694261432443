<template>
<div class="manageSider">
  <div class="logo" >
    <span @click="()=>{this.$router.push('/');this.select='[0]'}" style="cursor: pointer">
      <span v-if="collapsed === false">后台管理系统</span>
      <img
          v-if="collapsed"
          height="30"
          width="30"
          src="@/assets/logo.png">
    </span>
  </div>
  <a-menu theme="dark" mode="inline"
          @openChange = "onOpenChange"
          :selectedKeys="[$route.path]"
          v-model=select
          :open-keys="openKeys"
  >
    <a-sub-menu :key="menu.id" v-for="menu in menus">
      <span slot="title">
         <admin_con type="icon-settings"/>
        <span>{{ menu.name }}</span>
      </span>
      <a-menu-item :key="menuChildren.path"  v-for="menuChildren in menu.childMenu">
        <router-link :to="menuChildren.path">
          <span>{{menuChildren.name}}</span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</div>
</template>

<script>
import {admin_icon} from '@/utils/myIcon'
import {manageMenus} from '@/service/menu_api'
export default {
  name: "ManageSider",
  components:{
    admin_con: admin_icon,
  },
  props:["collapsed"],
  data(){
    return{
      menus:'',
      select:['0'],
      openKeys:[],
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
    }
  },
  async created() {
    await this.getMenus();
    await this.change_select()
  },
  mounted() {

  },
  watch:{

  },
  methods:{
    async change_select(){
      let path = this.$route.path;
      let select  = []
      select.push(path)
      this.select = select
      let menuData = this.menus;
      for (const menuDataKey in menuData) {
        for (const itemKey in menuData[menuDataKey].childMenu){
          let myList = [];
          if (menuData[menuDataKey].childMenu[itemKey].path === path) {
            myList.push(menuData[menuDataKey].childMenu[itemKey].parentId)
            this.openKeys =myList
            break;
          }
        }
      }
    },

    async getMenus(){
      const response = await manageMenus();
      if (response.code === 0){
        this.menus = response.data
      }
    },


    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>